import React, { useMemo, useState } from "react";

import { DateRangePicker, DateRange } from "mui-daterange-picker";
import moment from "moment";

// @mui material components
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";

// Otis Admin PRO React context
import { useMaterialUIController, setSelectedMunicipal } from "context";
import { setSelectedReportType, setSelectedReportSubType, setSelectedGroupByFilter, setReportIdFilter, setReportFlagFilter, setReportTagFilter, setReportDateFilter } from "context";
import MDTypography from "components/MDTypography";
import { updateAgentUser } from "services/firebaseServices";

const reportFlagFilters = [
  {
    id: 0,
    label: "All"
  },
  {
    id: 1,
    label: "Flagged"
  }
]

const reportGroupByFilters = [
  {
    id: "0",
    label: "All"
  },
  {
    id: "10",
    label: "Groupped reports"
  },
  {
    id: "20",
    label: "Standard reports"
  },
]

function Header() {
  const [controller, dispatch] = useMaterialUIController();
  const { municipals, selectedMunicipal, reportTypesIssues, selectedReportType, selectedReportSubType, selectedGroupByFilter, reportFlagFilter, reportTagFilter, reportDateFilter, user, allReportTags } = controller;
  const [reportIdInput, setReportIdInput] = useState('')
  const [open, setOpen] = useState(false);

  // console.log("municipals", municipals)
  // console.log("selectedMunicipal", selectedMunicipal)

  const reportTagsData = useMemo(() => { 
    return allReportTags
            .filter(t => !t.fullName.includes("Corporation"))
            .map((m, i) => {
              return {
                id: m.id,
                label: m.fullName,
                ...m
              }
            })
  }, [allReportTags])

  const municipalsData = useMemo(() => { 
    let data = municipals
            .filter(m=> user.municipals.includes(m.id))
            .map((m, i) => {
              return {
                id: m.id,
                label: m.name,
                ...m
              }
            })
            
    if(data.length) {
      if(data.length === municipals.length)
        return [{id: 'all', label: 'All'}, ...data]
      else
        return data
    }
    else 
      return []
  }, [municipals])

  const reportTypesData = useMemo(() => { 
    let data = reportTypesIssues.map((m) => {
      return {
        id: m.id,
        label: m.type
      }
    })
    if(data.length)
      return [{id: 'all', label: 'All'}, ...data]
    else 
      return []
  }, [reportTypesIssues])

  const reportSubTypesData = useMemo(() => { 
    if(!!selectedReportType && selectedReportType !== "all") {
      let data = []
      reportTypesIssues.find(rt => rt.id === selectedReportType).issues.forEach((m) => {
        if(!!m.values === false) {
          data.push({
            id: m.label,
            label: m.label
          })
        } else {
          m.values.forEach(e => data.push({
            id: e,
            label: e
          }))
        }
      })
      if(data.length > 1){
        return [{id: 'all', label: 'All'}, ...data]
      } else {
        return []
      }
    } else {
      return []
    }
  }, [selectedReportType])

  const handleReportIdSearch = () => {
    // console.log("handleReportIdSearch", reportIdInput)
    setReportIdFilter(dispatch, reportIdInput)
  }

  const handleDateFilterChange = (dtRg) => {
    // console.log("handleDateFilterChange", dtRg)
    setReportDateFilter(dispatch, dtRg)
  }

  const toggle = () => setOpen(!open)

  const clearDateFilter = () => {
    setReportDateFilter(dispatch, null)
    setOpen(false)
  }

  const renderDateRangeValue = () => {
    // console.log("reportDateFilter", reportDateFilter)
    let val = ""
    if(reportDateFilter?.label) {
      val = reportDateFilter.label
    } else if(reportDateFilter?.startDate) {
      val = moment(reportDateFilter.startDate).format("MM/DD/YY") + " - " + moment(reportDateFilter.endDate).format("MM/DD/YY")
    }
    return val
  }

  // const handleclick = () => {
  //   updateAgentUser("qBHVViuc8oRNQ5Zi9xOYfxK4kgL2")
  // }

  return (
    <MDBox sx={{width: '100%'}}>
      {/* <MDButton variant="text" color="info" onClick={handleclick}><Icon>close</Icon></MDButton> */}
      <MDTypography>Filters</MDTypography>
      <MDBox className="rp-filters" display="flex" alignItems="center" mb={2} sx={{flexWrap: 'wrap'}}>

        <MDBox mt={1} pr={1}>
          <MDBox sx={{width: 200, position: 'relative'}}>
            <MDInput variant="standard" label={"Select date range"} value={renderDateRangeValue()} onClick={toggle} />
            <MDBox sx={{position: 'absolute', right: 30, top: 10}}>
              <MDButton variant="text" color="info" onClick={clearDateFilter}><Icon>close</Icon></MDButton>
            </MDBox>
            <MDBox sx={{ position: 'absolute', top: 50, left: 0, zIndex: 1000 }}>
              <DateRangePicker
                open={open}
                toggle={toggle}
                maxDate={new Date()}
                onChange={(range) => handleDateFilterChange(range)}
              />
            </MDBox>
          </MDBox>
        </MDBox>
        
        <MDBox mt={1} pr={1}>
          {reportTagsData.length>0 &&
            <MDBox sx={{width: 200}}>
              <Autocomplete
                options={[...reportTagsData]}
                value={reportTagsData.find(r=>r.id===reportTagFilter)}
                onChange={(event, newValue) => {
                  console.log("newValue ", newValue)
                  setReportTagFilter(dispatch, newValue?.id ?? [])
                }}
                inputValue={reportTagsData.find(r=>r.id===reportTagFilter)?.label}
                onInputChange={(event, newInputValue) => {
                  // console.log("newInputValue ", newInputValue)
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" label="Report Tag" />}
              />
            </MDBox>
          }
        </MDBox>
        
        <MDBox mt={1} pr={1}>
          {reportFlagFilters.length>0 &&
            <MDBox sx={{width: 200}}>
              <Autocomplete
                options={[...reportFlagFilters]}
                value={reportFlagFilters.find(r=>r.id===reportFlagFilter)}
                onChange={(event, newValue) => {
                  // console.log("newValue ", newValue)
                  setReportFlagFilter(dispatch, newValue.id)
                }}
                inputValue={reportFlagFilters.find(r=>r.id===reportFlagFilter)?.label}
                onInputChange={(event, newInputValue) => {
                  // console.log("newInputValue ", newInputValue)
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" label="Report Flag" />}
              />
            </MDBox>
          }
        </MDBox>

        <MDBox mt={1} pr={1}>
          <MDBox sx={{width: 200,}} display="flex" alignItems="center">
            <MDInput 
              variant="standard" 
              label="Report ID" 
              value={reportIdInput}
              onChange={({target}) => {
                setReportIdInput(target.value)
              }}
            />
            <MDButton variant="gradient" color="info" iconOnly size="small" onClick={handleReportIdSearch}>
              <Icon>search</Icon>
            </MDButton>
          </MDBox>
        </MDBox>

        <MDBox mt={1} pr={1}>
          {reportGroupByFilters.length>0 &&
            <MDBox sx={{width: 200,}}>
              <Autocomplete
                options={[...reportGroupByFilters]}
                value={reportGroupByFilters.find(r=>r.id===selectedGroupByFilter)}
                onChange={(event, newValue) => {
                  // console.log("newValue ", newValue)
                  setSelectedGroupByFilter(dispatch, newValue.id)
                }}
                inputValue={reportGroupByFilters.find(r=>r.id===selectedGroupByFilter)?.label}
                onInputChange={(event, newInputValue) => {
                  // console.log("newInputValue ", newInputValue)
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" label="Report Type" />}
              />
            </MDBox>
          }
        </MDBox>

        {/* <MDBox height="75%" alignSelf="flex-end">
          <Divider orientation="vertical" />
        </MDBox> */}

        <MDBox mt={1} pr={1}>
          {reportTypesData.length>0 &&
            <MDBox sx={{width: 200,}}>
              <Autocomplete
                options={[...reportTypesData]}
                value={reportTypesData.find(r=>r.id===selectedReportType)}
                onChange={(event, newValue) => {
                  // console.log("newValue ", newValue)
                  setSelectedReportType(dispatch, newValue.id)
                }}
                inputValue={reportTypesData.find(r=>r.id===selectedReportType)?.label}
                onInputChange={(event, newInputValue) => {
                  // console.log("newInputValue ", newInputValue)
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" label="Report Category" />}
              />
            </MDBox>
          }
        </MDBox>

        {/* {reportSubTypesData.length>0 &&
          <MDBox height="75%" alignSelf="flex-end">
            <Divider orientation="vertical" />
          </MDBox>
        } */}

        <MDBox mt={1} pr={1}>
          {reportSubTypesData.length>0 &&
            <MDBox sx={{width: 200,}}>
              <Autocomplete
                options={[...reportSubTypesData]}
                value={reportSubTypesData.find(r=>r.id===selectedReportSubType)}
                onChange={(event, newValue) => {
                  // console.log("newValue ", newValue)
                  setSelectedReportSubType(dispatch, newValue.id)
                }}
                inputValue={reportSubTypesData.find(r=>r.id===selectedReportSubType)?.label}
                onInputChange={(event, newInputValue) => {
                  // console.log("newInputValue ", newInputValue)
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" label="Report Sub-Category" />}
              />
            </MDBox>
          }
        </MDBox>

        {/* <MDBox height="75%" alignSelf="flex-end">
          <Divider orientation="vertical" />
        </MDBox> */}
        <MDBox mt={0.5} pr={1}>
          {municipalsData.length>0 &&
            <MDBox sx={{width: 200,}}>
              <Autocomplete
                options={[...municipalsData]}
                value={municipalsData.find(m=>m.id === selectedMunicipal)}
                onChange={(event, newValue) => {
                  // console.log("newValue ", newValue)
                  setSelectedMunicipal(dispatch, newValue.id)
                }}
                inputValue={municipalsData.find(m=>m.id === selectedMunicipal)?.label}
                onInputChange={(event, newInputValue) => {
                  // console.log("newInputValue ", newInputValue)
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" label="Municipal" />}
              />
            </MDBox>
          }
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Header;
