import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { useTheme } from '@mui/material/styles';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import FormField from "components/FormField";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { logoutUser } from "services/userServices"

import { useMaterialUIController } from "context";

// Images
import bgImage from "assets/images/bg-sign-in-new.jpeg";
import logo from "assets/images/logo.png";
import { saveAccountDeleteRequest } from "services/firebaseServices";

const initialValues={ email: '', phoneNumber: '', password: '', name: '' }
const validations = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("This field is required"),
  phoneNumber: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
  name: Yup.string().required("This field is required")
})

function UserDeleteDataRequest() {
  const theme = useTheme();
  const [controller, dispatch] = useMaterialUIController();
  const { tenant } = controller;
  const [rememberMe, setRememberMe] = useState(false);
  const [showError, setShowError] = useState(false)

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (values, actions) => {
    // console.log("handleSubmit tenant", tenant)
    const auth = getAuth()
    signInWithEmailAndPassword(auth, values.email, values.password)
    .then(async (userCredential) => {
      console.log("userCredential", userCredential);
      
      let data = {...values}
      delete data.password
      const response = await saveAccountDeleteRequest(data)
      if(response) {
        actions.resetForm()
        alert("Your delete request has been registered with us. Our team will take action on it soon and contact you on provided email.")
      } else {
        setShowError("Something went wrong. Please try again.")
      }
      actions.resetForm()
      logoutUser()
    })
    .catch(error => {
      alert("Invalid user request")
    })
    actions.setSubmitting(false)
  }

  const bgIMage = tenant? tenant.bg_image : bgImage
  const tenantLogo = tenant? tenant.logo : logo

  return (
    <BasicLayout image={bgIMage} requestForm>
      <MDBox>
        <MDBox sx={{width: '150px', height: '100px', position: 'relative', margin: '0 auto', marginBottom: '40px'}}>
          <img src={tenantLogo} style={{width: '100%', height: '100%'}} />
        </MDBox>
      </MDBox>
      <Card>
        <MDBox
          variant="gradient"
          // bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          sx={{backgroundColor: theme.palette.info.main}}
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Local TT Delete Account and Associate Data Request Form
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id="requestForm" autoComplete="off">
                <MDBox mb={2}>
                  <FormField
                    type={"text"}
                    label={"Email"}
                    name={"email"}
                    value={values.email}
                    placeholder={"Email"}
                    error={errors.email && touched.email}
                    success={values.email.length > 0 && !errors.email}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormField
                    type={"text"}
                    label={"Phone Number"}
                    name={"phoneNumber"}
                    value={values.phoneNumber}
                    placeholder={"Phone Number"}
                    error={errors.phoneNumber && touched.phoneNumber}
                    success={values.phoneNumber.length > 0 && !errors.phoneNumber}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormField
                    type={"password"}
                    label={"Password"}
                    name={"password"}
                    value={values.password}
                    placeholder={"Password"}
                    error={errors.password && touched.password}
                    success={values.password.length > 0 && !errors.password}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormField
                    type={"text"}
                    label={"Name"}
                    name={"name"}
                    value={values.name}
                    placeholder={"Name"}
                    error={errors.name && touched.name}
                    success={values.name.length > 0 && !errors.name}
                  />
                </MDBox>
                {/* <MDBox display="flex" alignItems="center" ml={-1}>
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Remember me
                  </MDTypography>
                </MDBox> */}
                <MDBox mt={4} mb={1}>
                  <MDButton type="submit" disabled={isSubmitting} variant="gradient" sx={{color: '#ffffff', backgroundColor: theme.palette.info.main}} fullWidth>
                    Submit
                  </MDButton>
                </MDBox>
                {/* <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Don&apos;t have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/authentication/sign-up/cover"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign up
                    </MDTypography>
                  </MDTypography>
                </MDBox> */}
              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
      
      {!!showError &&
        <MDAlert color="error" dismissible onDismiss={() => setShowError(false)}>
          <MDTypography variant="body2" color="white">
            {showError}
          </MDTypography>
        </MDAlert>
      }
    </BasicLayout>
  );
}

export default UserDeleteDataRequest;
