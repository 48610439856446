import { getAuth } from "firebase/auth";
import jwt_decode from "jwt-decode";

export const isLogin = () => {
  const auth = getAuth();
  const user = auth.currentUser;

  console.log("getAuth().currentUser", user);
  return !!user
}

export const getUserToken = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user?.accessToken || null
}

export const generateUserToken = async () => {
  const auth = getAuth();
  // const user = auth.currentUser;
  // return user?.accessToken || null

  try {
    const idToken = await auth.currentUser.getIdToken(true)
    return idToken
  } catch (error) {
    console.log("getIdToken error: ", error);
    return null
  }
}

export const isAdminToken = () => {
  const token = getUserToken()
  if (token) {
    var decoded = jwt_decode(token);
    // console.log("decoded", decoded);
    return decoded.role === "ADMIN" && !!decoded?.subdomain === false
  }
  return token
}

export const isAgentToken = () => {
  const token = getUserToken()
  if (token) {
    var decoded = jwt_decode(token);
    // console.log("decoded", decoded);
    return decoded.role === "AGENT" && !!decoded?.subdomain === false
  }
  return token
}

export const isViewerToken = () => {
  const token = getUserToken()
  if (token) {
    var decoded = jwt_decode(token);
    // console.log("decoded", decoded);
    return decoded.role === "VIEWER" && !!decoded?.subdomain === false
  }
  return token
}

export const isSubdomainUser = (curSubDomain) => {
  const token = getUserToken()
  if (token) {
    var decoded = jwt_decode(token);
    // console.log("decoded", decoded);
    return decoded.role === "VIEWER" && decoded.subdomain === curSubDomain
  }
  return token
}

export const textEllipses = (text, limit) => {
  return text.length > limit ? text.substring(0, limit) + " ..." : text
}

export const getSubdomain = () => {
  return window.location.hostname.split('.')[0]
}

export const encodeImageUrl2 = (url) => {
  let substr = url.substring(url.indexOf('/reportImages')+13, url.indexOf('?alt='))
  let substr1 = substr.replace("/", "%2F")
  let val = url.replace(substr, substr1)
  return val
}
export const encodeImageUrl = (url) => {
  return window.encodeURIComponent(encodeImageUrl2(url)).replace("52").replace("52")
}