import { useState, useEffect, useMemo } from "react";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormField from "components/FormField";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "containers/Navbars/DashboardNavbar";
import Footer from "containers/Footer";
import DataTable from "containers/Tables/DataTable";

import { postNotification, getBroadcastMessages, deleteBroadcastMessageById } from "services/firebaseServices"

// React context
import { useMaterialUIController } from "context";

const BROADCAST_TYPES = [
  {
    id: 'ADMIN_MESSAGE',
    label: 'Admin message',
    title: 'Nationwide Broadcast'
  },
  {
    id: 'MUNICIPAL_MESSAGE',
    label: 'Municipal Message',
    title: 'Corporation Broadcast'
  }
]

const initialValues = {
  type: "ADMIN_MESSAGE",
  title: 'Nationwide Broadcast',
  topic: "PUBLIC",
  message: "",
}
const validations = Yup.object().shape({
  type: Yup.string().required("This field is required"),
  title: Yup.string(),
  topic: Yup.string().required("This field is required"),
  message: Yup.string().required("This field is required")
})

const actionsColumn = { 
  Header: "Actions", 
  accessor: "actions", 
  width: "10%", 
  sorting: false,
  Cell: ({value}) => <MDBox>
    <Tooltip key={"Actions"} title={"Actions"} placement="bottom">
      {value.menu}
    </Tooltip>
  </MDBox>
}

const dataTableData = {
  columns: [
    { Header: "type", accessor: "type", width: "20%" },
    // { Header: "topic", accessor: "topic", width: "25%" },
    { Header: "message", accessor: "message", width: "100px", Cell: props => <div style={{maxWidth: "50vw"}}>{props.value}</div> },
    { Header: "date", accessor: "createdAt", Cell: props => <div>{moment(props.value).format("MM-DD-YY LT")}</div>  },
    {...actionsColumn}
  ],
  rows: []
}

const initialSBConfig = {
  color: "info",
  icon: "notifications",
  content: "",
  open: false,
}

function Broadcast() {
  const [controller] = useMaterialUIController();
  const { darkMode, municipals, user } = controller;
  const [snackbarConfig, setSnackbarConfig] = useState({...initialSBConfig})
  const [tableData, setTableData] = useState(dataTableData)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const data = await getBroadcastMessages()
    const messageData = data.map((item, i) => {
      return {
        ...item,
        actions: {
          menu: <MoreMenu 
                  data={item} 
                  handleMenuActions={handleMenuActions}
                  userRole={user.role}
                />
        }
      }
    })
    setTableData({
      ...tableData,
      rows: [
        ...messageData
      ]
    })
  }

  const municipalsData = useMemo(() => { 
    return municipals.map((m, i) => {
      return {
        id: m.id,
        label: m.name,
        ...m
      }
    })
  })

  const handleMenuActions = (actionType, data) => {
    switch (actionType) {

      case "handleDelete":
        if(confirm("Do you want to delete message?")){
          // Delete broadcast action called.
          deleteBroadcastMessage(data)
        }
        break;

      default:
        break;
    }
  }

  const deleteBroadcastMessage = (message) => {
    deleteBroadcastMessageById(message)
    .then(() => {
      // update broadcast list
      getData()

      setSnackbarConfig({
        ...snackbarConfig,
        color: "success",
        title: "Broadcast Message",
        content: `Broadcast Message Deleted`,
        open: true,
        close: toggleSnackbarConfig
      })
      setLoading(false)
    })
  }

  const toggleSnackbarConfig = () => setSnackbarConfig({...initialSBConfig})

  const handleSubmit = async (values, actions) => {
    console.log("handleSubmit", values)

    const response = await postNotification({
      ...values,
      createdAt: new Date().getTime()
    })
    if(response) {
      setSnackbarConfig({
        ...snackbarConfig,
        color: "info",
        icon: 'notifications',
        title: "Broadcast Message",
        content: `Broadcast Message Sent`,
        open: true,
        close: toggleSnackbarConfig
      })
      actions.resetForm()
    } else {
      actions.setSubmitting(false);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox
          position="relative"
          my={4}
          sx={({
            palette: { light, background },
            functions: { pxToRem },
            borders: { borderRadius },
          }) => ({
            "& .react-kanban-column": {
              backgroundColor: darkMode ? background.card : light.main,
              width: pxToRem(450),
              margin: `0 ${pxToRem(10)}`,
              padding: pxToRem(20),
              borderRadius: borderRadius.lg,
            },
          })}
        >
          <Grid container display={"flex"} justifyContent="center" alignItems={"center"}>
            <Grid item xs={12} lg={8}>
            <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id="broadcastForm" autoComplete="off">
                    <Card variant="outlined">
                      <MDBox p={2}>
                        <MDBox mb={2}>
                          <MDTypography variant="h5">{"Broadcast Message Form"}</MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <Autocomplete
                            options={BROADCAST_TYPES}
                            value={BROADCAST_TYPES.find(m=>m.id === values.type)}
                            onChange={(event, newValue) => {
                              // console.log("newValue ", newValue)
                              setFieldValue("type", newValue.id)
                              setFieldValue("title", newValue.title)
                              if(newValue.id === "MUNICIPAL_MESSAGE") {
                                setFieldValue("topic", municipalsData[0].id)
                              } else {
                                setFieldValue("topic", "PUBLIC")
                              }
                            }}
                            inputValue={BROADCAST_TYPES.find(m=>m.id === values.type)?.label}
                            onInputChange={(event, newInputValue) => {
                              // console.log("newInputValue ", newInputValue)
                            }}
                            renderInput={(params) => <MDInput {...params} variant="standard" label="Broadcast Message Type" />}
                          />
                        </MDBox>
                        {values.type === "MUNICIPAL_MESSAGE"?
                          <MDBox mb={2}>
                            {municipalsData.length>0 &&
                              <Autocomplete
                                options={[...municipalsData]}
                                value={municipalsData.find(m=>m.id === values.topic)}
                                onChange={(event, newValue) => {
                                  // console.log("newValue ", newValue)
                                  setFieldValue("topic", newValue.id)
                                }}
                                inputValue={municipalsData.find(m=>m.id === values.topic)?.label}
                                onInputChange={(event, newInputValue) => {
                                  // console.log("newInputValue ", newInputValue)
                                }}
                                renderInput={(params) => <MDInput {...params} variant="standard" label="Municipal" />}
                              />
                            }
                          </MDBox>
                        : null}
                        <MDBox mb={2}>
                          <FormField 
                            label="Message" 
                            placeholder="Type here... (max 500 char)"
                            name="message"
                            value={values.message}
                            error={errors.message && touched.message}
                            success={values.message.length > 0 && !errors.message}
                            multiline 
                            rows={5} 
                          />
                        </MDBox>
                        <MDBox display="flex" justifyContent="flex-end">
                          <MDButton variant="contained" type="submit" disabled={isSubmitting} color="info">Send</MDButton>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox pt={4} my={4}>
          {loading &&
            <MDBox sx={{
              overflow: 'hidden'
            }}>
              <LinearProgress />
            </MDBox>
          }
          <DataTable table={tableData} />
        </MDBox>

      </MDBox>

      <MDSnackbar
        {...snackbarConfig}
      />

      <Footer />
    </DashboardLayout>
  )
}

export default Broadcast

const MoreMenu = ({ data, handleMenuActions, userRole }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleDelete = () => {
    handleMenuActions("handleDelete", data)
    handleClose()
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {userRole !== "VIEWER" &&
          <MenuItem onClick={handleDelete}>{"Delete"}</MenuItem>
        }
      </Menu>
    </>
  )
}