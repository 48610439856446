import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

import { useMaterialUIController } from "context";
import LoadingScreen from "containers/LoadingScreen";

const PrivateOutlet = ({ component: Component, ...rest }) => {
  const [controller] = useMaterialUIController();
  const { isLoggedIn } = controller
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    
    !!isLoggedIn ?
      <Outlet />
      : isLoggedIn === null ? <LoadingScreen /> : <Navigate to="/authentication/sign-in" />
  );
};

export default PrivateOutlet;