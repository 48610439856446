export const sessionTypes = {
  LOAD_SESSION: 'LOAD_SESSION',
  SET_SESSION_SUCCESS: 'SET_SESSION_SUCCESS',
  SET_SESSION_OUT: 'SET_SESSION_OUT',
  SET_TENANT: 'SET_TENANT'
}

export const municipalTypes = {
  SET_MINICIPAL: 'SET_MINICIPAL',
  SET_SELECTED_MINISTRIES: 'SET_SELECTED_MINISTRIES',
  SET_REPORT_TYPES_ISSUES: 'SET_REPORT_TYPES_ISSUES',
  SET_REPORT_TAGS: 'SET_REPORT_TAGS',
  SET_SELECTED_REPORT_TYPE: 'SET_SELECTED_REPORT_TYPE',
  SET_SELECTED_REPORT_SUB_TYPE: 'SET_SELECTED_REPORT_SUB_TYPE',
  SET_SELECTED_GROUP_BY_FILTER: 'SET_SELECTED_GROUP_BY_FILTER',
  SET_REPORT_ID_FILTER: 'SET_REPORT_ID_FILTER',
  SET_REPORT_FLAG_FILTER: 'SET_REPORT_FLAG_FILTER',
  SET_REPORT_TAG_FILTER: 'SET_REPORT_TAG_FILTER',
  SET_REPORT_DATE_FILTER: 'SET_REPORT_DATE_FILTER',
}