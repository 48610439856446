import React from 'react'

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { DialogContent, DialogContentText, TextField, DialogActions, Box, Button } from "@mui/material";
import { Save } from "@mui/icons-material";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles({
//   inputWrapper: {
//     marginTop: "15px !important",
//   }
// });

const ResetPasswordForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
  onClose
}) => {
  // const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to reset password? That user will not be able to login with existing password
        </DialogContentText>
        <Box mx={3}>
          <MDInput 
            name="newpassword"
            label="NewPassword"
            type="password"
            fullWidth
            value={values.newpassword}
            onChange={handleChange}
            error={touched.newpassword && Boolean(errors.newpassword)}
            helperText={touched.newpassword && errors.newpassword}
            variant="standard"
          />
          {/* <TextField
            fullWidth
            name="newpassword"
            label="NewPassword"
            type="password"
            value={values.newpassword}
            onChange={handleChange}
            error={touched.newpassword && Boolean(errors.newpassword)}
            helperText={touched.newpassword && errors.newpassword}
            // classes={{ root: classes.inputWrapper }}
            variant="standard"
          /> */}


          <DialogActions>
            <Box textAlign={"center"}>
              <MDButton
                variant="outlined"
                color="secondary"
                style={{ textAlign: "center", marginRight: '1rem' }}
                // classes={{ root: classes.inputWrapper }}
                onClick={onClose}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="contained"
                color="success"
                type="submit"
                disabled={isSubmitting}
                style={{ textAlign: "center" }}
                startIcon={<Save />}
                // classes={{ root: classes.inputWrapper }}
              >
                Save
              </MDButton>
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </form>
  )
}

export default ResetPasswordForm;