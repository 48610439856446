import React, { useEffect, useState, useCallback } from "react";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";

import { useKanban, addUser } from "layouts/dashboards/kanban/context"
import { getUserInfo } from "services/firebaseServices"

import defaultAvatar from "assets/images/defaultAvatar.png"

const ReportorAvatar = (props) => {
  const [stateKanban, dispatchKanban] = useKanban()
  const { users } = stateKanban;
  const [reportor, setReportor] = useState(null)
  
  useEffect(() => {
    getReportUser()
  }, [])

  const getReportUser = useCallback(async () => {
    if(!!users[props.uid] === false) {
      const userSnap = await getUserInfo(props.uid)
      if(userSnap.exists) {
        setReportor(userSnap.data())
        addUser(dispatchKanban, userSnap.data())
      }
    } else {
      console.log("Save request!")
      setReportor(users[props.uid])
    }
  }, [props.uid])

  return (
    <MDBox display={'flex'} justifyContent={"center"} alignItems="center">
      <MDAvatar src={reportor?.profilePicUrl || defaultAvatar} alt="profile-image" size="md" shadow="sm" />
      <MDTypography sx={{marginLeft: '10px'}} variant="body" fontWeight="regular">{`${reportor?.firstName || ''} ${reportor?.lastName || ''}`}</MDTypography>
    </MDBox> 
  )
}

export default ReportorAvatar