import moment from "moment";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

import TimelineList from "containers/Timeline/TimelineList";
import TimelineItem from "containers/Timeline/TimelineItem";

function TimelineView({ timeline, type, isEditable, submitEditAction, deleteAction }) {
  const timelineData = timeline.filter(t => t.status === "COMPLETED")
  return (
    <MDBox>
      {timelineData.map((item, index) => {
        return (
          <TimelineList title={item.title}>
            {item[type]?.history ?
              <>
                {item[type].history.map((itm, idx) => {
                  let dateTime = itm?.modifiedAt ?? itm.createdAt
                  return (
                    <TimelineItem
                      key={`timeline-${idx}`}
                      color="info"
                      icon="notes"
                      title={""}
                      dateTime={dateTime ? moment(dateTime).format("DD MMM YYYY LT") : "Created At: N/A"}
                      description={itm.message || "Notes: N/A"}
                      isEditable={isEditable}
                      submitEditAction={(data, cb) => {
                        timeline[index][type].history[idx].message = data
                        timeline[index][type].history[idx].modifiedAt = new Date().getTime(),
                          submitEditAction(timeline, cb)
                      }}
                      deleteAction={(cb) => {
                        timeline[index][type].history.splice(idx, 1)
                        deleteAction(timeline, cb)
                      }}
                    />
                  )
                }
                )}
              </>
              :
              <TimelineItem
                key={`timeline-${index}`}
                color="info"
                icon="notes"
                title={""}
                dateTime={item[type]?.createdAt ? moment(item[type].createdAt).format("DD MMM YYYY LT") : "Created At: N/A"}
                description={item[type]?.message || "Notes: N/A"}
                isEditable={isEditable}
                submitEditAction={(data, cb) => {
                  timeline[index][type].message = data
                  timeline[index][type].modifiedAt = new Date().getTime(),
                    submitEditAction(timeline, cb)
                }}
                deleteAction={() => {
                  delete timeline[index][type].modifiedAt
                  delete timeline[index][type].createdAt
                  timeline[index][type].message = ""
                  deleteAction(timeline)
                }}
              />
            }
          </TimelineList>
        )
      })}
    </MDBox>
  )
}

export default TimelineView