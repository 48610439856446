import React, { useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Clear';

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "components/FormField";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "containers/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "containers/Timeline/TimelineItem/styles";

const valds = Yup.object().shape({
  desc: Yup.string().required("This field is required").max(5000)
})

const TimelineItem = ({ color, icon, title, dateTime, description, lastItem, isEditable, submitEditAction, deleteAction }) => {
  const isDark = useTimeline();
  const [showEditForm, setShowEditForm] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const onEdit = () => {
    setShowEditForm(true)
  }
  const onDelete = () => {
    setSubmitting(true)
    if(confirm(`Are you sure you want to delete?`)) {
      deleteAction((success, error) => {
        if(error) {
          console.log(error)
          alert(error)
        }
        setSubmitting(false)
      })
    }
  }

  const onCancel = () => {
    setShowEditForm(false)
  }

  const onSave = async (values, action) => {
    // console.log("Values", values)
    setSubmitting(true)
    submitEditAction(values.desc, (success, error) => {
      if(success) {
        setShowEditForm(false)
      } else {
        console.log("error", error)
        alert(error)
      }
      setSubmitting(false)
    })
  }

  return (
    <MDBox position="relative" sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        // top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
          {title}
        </MDTypography>
        <MDBox>
          <MDTypography variant="caption" color={isDark ? "secondary" : "text"}>
            {dateTime}
          </MDTypography>
          {!!isEditable ?
            !!showEditForm === false ?
              <>
                <IconButton aria-label="Edit" onClick={onEdit} size="small" sx={{marginLeft: '10px', marginTop: '-5px'}}>
                  <EditIcon/>
                </IconButton>
                <IconButton aria-label="Edit" onClick={onDelete} disabled={submitting} size="small" sx={{marginLeft: '10px', marginTop: '-5px'}}>
                  <DeleteIcon/>
                </IconButton>
              </>
              :
              <>
                <IconButton aria-label="Cancel" onClick={onCancel} size="small" sx={{marginLeft: '10px', marginTop: '-5px'}}>
                  <CancelIcon/>
                </IconButton>
              </>
            : null
          }
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          {showEditForm?
            <Formik
              initialValues={{
                desc: description
              }}
              validationSchema={valds}
              onSubmit={onSave}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form id="notesForm" autoComplete="off">
                    <MDBox mb={2}>
                      <FormField 
                        label="Type here..." 
                        name="desc"
                        value={values.desc}
                        error={errors.desc && touched.desc}
                        success={values.desc.length > 0 && !errors.desc}
                        multiline 
                        rows={5} 
                      />
                    </MDBox>
                    <MDBox display="flex" justifyContent="flex-end">
                      <MDButton variant="contained" type="submit" disabled={submitting} color="info">Save</MDButton>
                    </MDBox>
                  </Form>
                )}
              </Formik>
            :
            description ? (
              <MDTypography variant="button" color={isDark ? "white" : "dark"}>
                {description}
              </MDTypography>
            ) : null}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
