import { getFirestore, collection, doc, getDocs, getDoc, addDoc, query, where, orderBy, runTransaction, limit, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, deleteObject, list } from "firebase/storage";

import firebaseApp from "../firebase";

const db = getFirestore(firebaseApp);

export const getLocations = async () => {
  try {
    let data = []
    await getDocs(collection(db, 'locations'))
      .then(querySnap => {
        querySnap.forEach(doc => {
          data.push({
            id: doc.id,
            ...doc.data()
          })
        });
      })
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getReportTypesIssues = async () => {
  try {
    let data = []
    await getDocs(collection(db, 'reportTypesIssues'))
      .then(querySnap => {
        querySnap.forEach(doc => {
          data.push({
            id: doc.id,
            ...doc.data()
          })
        });
      })
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getReportTags = async () => {
  try {
    let data = []
    await getDocs(collection(db, 'reportTags'))
      .then(querySnap => {
        querySnap.forEach(doc => {
          data.push({
            id: doc.id,
            ...doc.data()
          })
        });
      })
    return data
  } catch (error) {
    console.log("allReportTags errors", error)
    return []
  }
}

export const getReportsOld = async (municipalId, reportType, reportTag=null) => {
  let data = []
  try {
    const collRef = collection(db, 'reports')
    const queryRef = query(collRef, where("municipal", '==', municipalId), orderBy('createdAt', 'desc'))
    // if (arr && arr.length)()
    await getDocs(queryRef)
      .then(querySnap => {
        querySnap.forEach(doc => {
          const docData = doc.data()
          if(reportType === "all" || (reportType !== "all" && reportType === docData.type)) {
            if(reportTag) {
              if(docData.reportTags && docData.reportTags.includes(reportTag)) {
                data.push({
                  id: doc.id,
                  ...docData
                })
              } 
            } else {
              data.push({
                id: doc.id,
                ...docData
              })
            }
          }
        });
      }, reason => {
        console.log("reason", reason)
      })
  } catch (error) {
    console.log("getReports error", error)
  }
  return data
}

var unSubRptListener = null;
export const getReports = async (municipalId, reportType, tenantFilter=null, reportID=0, reportFlagFilter, reportTagFilter, reportDateFilter, cb) => {
  try {
    let startTS = null,
        endTS = null
    if(reportDateFilter?.startDate) {
      startTS = new Date(reportDateFilter.startDate).getTime()
      endTS = new Date(reportDateFilter.endDate).getTime()
    }
    let reset = false
    const collRef = collection(db, 'reports')
    let args = []
    if(municipalId != "all") {
      args.push(where("municipal", '==', municipalId))
    }
    
    if(!!reportID) {
      // console.log("getReports reportID", reportID)
      args.push(where("reportUniqueId", '==', reportID))
      reset = true
    }if(reportFlagFilter == 1) {
      args.push(where("flaggedBy", '!=', 0))
      reset = true
    }
    if(startTS) {
      args.push(where("createdAt", '>=', startTS))
      args.push(where("createdAt", '<=', endTS))
      reset = true
    }
    if(!!reportTagFilter.length > 0) {
      // console.log("reportTagFilter", reportTagFilter)
      args.push(where("reportTags", 'array-contains-any', [reportTagFilter]))
      reset = true
    }

    if(reportFlagFilter == 1) {
      args.push(orderBy('flaggedBy', 'asc'))
    }
    let queryRef = query( collRef, 
      ...args,
      orderBy('createdAt', 'desc')
    )
    if(unSubRptListener != null) { 
      console.log("unsubscribe reports listener"); 
      unSubRptListener() 
    }
    
    unSubRptListener = onSnapshot(queryRef, (querySnap) => {
      let data = []
      console.log(`Received query snapshot of size ${querySnap.size}`);
      querySnap.forEach(doc => {
        const docData = doc.data()
        // console.log("docData", docData)
        if(reportType === "all" || (reportType !== "all" && reportType === docData.type)) {
          reset = true
          if(tenantFilter) {
            if(docData.reportTags && docData.reportTags.includes(tenantFilter)) {
              data.push({
                id: doc.id,
                ...docData
              })
            } 
          } else {
            data.push({
              id: doc.id,
              ...docData
            })
          }
        }
      });
      cb(data, reset)
    })
    
  } catch (error) {
    console.log("getReports error", error);
    return []
  }
  // return result
}

export const getMyReports = async (uid) => {
  let data = []
  const collRef = collection(db, 'reports')
  const queryRef = query(collRef, where("reportorUserId", '==', uid))
  await getDocs(queryRef)
    .then(querySnap => {
      querySnap.forEach(doc => {
        const docData = doc.data()
        data.push({
          id: doc.id,
          ...docData
        })
      });
    })
  return data
}

export const getUserInfo = async (uid) => {
  return await getDoc(doc(db, `users/${uid}`))
}

export const getAgentUserInfo = async (uid) => {
  return await getDoc(doc(db, `agentUsers/${uid}`))
}

export const toggleVettedReport = async (rid, newVal) => {
  try {
    await runTransaction(db, async (transaction) => {
      const rtDocRef = doc(db, `reports/${rid}`)
      const rtDoc = await transaction.get(rtDocRef);
      if (!rtDoc.exists()) {
        throw Error("Document does not exist!");
      }

      let reportData = rtDoc.data()
      if(!!reportData.reportorUserId === false) {
        throw Error("Operation probhited: Report user deleted");
      }
  
      return transaction.update(rtDocRef, { isVetted: newVal });
    });
    console.log("Transaction successfully committed!");
  } catch (e) {
    console.log("Transaction failed: ", e);
    throw e
  }
}

const getReportImagesFolderStoragePathFromUrl = (url) => {

  const baseUrl = "https://firebasestorage.googleapis.com:443/v0/b/local-new-ae77b.appspot.com/o/";
  const baseUrl2 = "https://firebasestorage.googleapis.com/v0/b/local-new-ae77b.appspot.com/o/";

  let imagePath = url.replace(baseUrl,"");
  imagePath = imagePath.replace(baseUrl2,"");

  const indexOfEndPath = imagePath.indexOf("?");
  imagePath = imagePath.substring(0,indexOfEndPath);
  
  imagePath = imagePath.replaceAll("%2F","/");

  const indexOfFolder = imagePath.lastIndexOf("/");
  imagePath = imagePath.substring(0, indexOfFolder)

  return imagePath;
}

export const deleteReportById = async (rt) => {
  try {
    await runTransaction(db, async (transaction) => {
      // 1. delete report images
      if(rt.images.length > 0) {
        const storage = getStorage();
        const folderPath = getReportImagesFolderStoragePathFromUrl(rt.images[0])
        const folderRef = ref(storage, folderPath)
        list(folderRef).then(files => {
          console.log("files", files.items)
          let promiseImageAll = []
          files.items.forEach(imgRef => {
            const imgPromise = new Promise((resolve, reject) => {
              deleteObject(imgRef)
              .then(() => {
                console.log("file deleted")
                resolve()
              })
              .catch((error) => {
                console.log("error delete file", error)
                reject()
              })
            })
            promiseImageAll.push(imgPromise)
          })
          Promise.all(promiseImageAll)
          .then(values => {
            console.log("all deleted", values)
          })
          .catch(err => {
            console.log("err => ", err)
          })
        })
      }
      // 2. delete reportLoc
      const querySnap = await getReportLoc(rt.id)
      if(!!querySnap.empty === false) {
        querySnap.forEach(async (doc) => {
          await transaction.delete(doc.ref)
          console.log("delete reportLoc", doc.id)
        });
      } else {
        console.log("getReportLoc record not found")
      }
      
      // 3. update bookmarkBy users "totalBookmarked" field
      if(rt?.bookmarkBy && rt?.bookmarkBy?.length > 0) {
        rt.bookmarkBy.forEach(async (uid) => {
          console.log("bookmarkBy", uid)
          const userDocRef = doc(db, `users/${uid}`)
          const userSnap = await transaction.get(userDocRef)
          const user = userSnap.data()
          const totalBookmarkedNew = user?.totalBookmarked > 0 ? user.totalBookmarked - 1 : 0
          console.log("user.totalBookmarked", totalBookmarkedNew)

          // code looks good but firestore is not updating
          await transaction.update(userDocRef, {
            totalBookmarked: totalBookmarkedNew
          }) 
          console.log("transaction.update", userSnap.ref)
          console.log("transaction.update", userDocRef)
        })
      }

      // 4. delete report
      const reptDocRef = doc(db, `reports/${rt.id}`)
      return await transaction.delete(reptDocRef)
    })
  } catch (error) {
    console.log("Transaction failed: ", e);
    throw e
  }
}

const getReportLoc = async (rid) => {
  const collRef = collection(db, 'reportLoc')
  const queryRef = query(collRef, where("reportID", '==', rid))
  return await getDocs(queryRef)
}

export const updateReportTags = async (rid, tags) => {
  try {
    await runTransaction(db, async (transaction) => {
      const rtDocRef = doc(db, `reports/${rid}`)
      const rtDoc = await transaction.get(rtDocRef);
      if (!rtDoc.exists()) {
        throw Error("Document does not exist!");
      }

      let reportData = rtDoc.data()
      if(!!reportData.reportorUserId === false) {
        throw Error("Operation probhited: Report user deleted");
      }
  
      return transaction.update(rtDocRef, { reportTags: tags });
    });
    console.log("Update Tags Transaction successfully committed!");
  } catch (e) {
    console.log("Update Tags Transaction failed: ", e);
    throw e
  }
}

export const updateAgentUser = async (id) => {
  const municipals = [
    "1jdBDdZO3U1EhFUrrltU",
    "2S39ojkrhUZG3E4mHozT",
    "5KmQiw97EssM3t0kH9Wp",
    "8hhok1KzSegWuOakin3q",
    "AhfeNo5ZbCqpKef7pts3",
    "D9fqwrFRzOAHVwv6t4pC",
    "KalcJdc9EowuNKr64N7Z",
    "PVI3SSXvSE45IZxzpvHu",
    "TJxhVG9TYrjcIaSDi9E7",
    "ZPNPpZ8nr80MyDkFK6tv",
    "b55MXv4sxAYA5Pjmpx89",
    "ddYOyBfVZ69zx86zp1e9",
    "mGTBpB8tcFmlJtqxbchu",
    "nDHPDh41KJxPYfnLPk45"
  ] 
  try {
    await runTransaction(db, async (transaction) => {
      const docRef = doc(db, `agentUsers/${id}`)
      return transaction.update(docRef, { municipals: municipals });
    });
    console.log("Update Tags Transaction successfully committed!");
  } catch (e) {
    console.log("Update Tags Transaction failed: ", e);
    throw e
  }
}

export const updateTimeline = async (rid, modifiedTimeline) => {
  try {
    await runTransaction(db, async (transaction) => {
      const rtDocRef = doc(db, `reports/${rid}`)
      const rtDoc = await transaction.get(rtDocRef);
      if (!rtDoc.exists()) {
        throw "Document does not exist!";
      }
      transaction.update(rtDocRef, { timeline: modifiedTimeline });
    });
    console.log("Transaction successfully committed!");
    return true
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false
  }
}

export const postNotification = async (data) => {
  try {
    await addDoc(collection(db, "notifications"), data);
    return true
  } catch (e) {
    console.log("Send notification failed: ", e);
    return false
  }
}

export const getBroadcastMessages = async () => {
  let data = []
  const collRef = collection(db, 'notifications')
  const queryRef = query(collRef, limit(100))
  await getDocs(queryRef)
    .then(querySnap => {
      querySnap.forEach(doc => {
        const docData = doc.data()
        data.push({
          id: doc.id,
          ...docData
        })
      });
    })
  return data
}

export const deleteBroadcastMessageById = async (item) => {
  try {
    await runTransaction(db, async (transaction) => {
      const docRef = doc(db, `notifications/${item.id}`)
      return await transaction.delete(docRef)
    })
  } catch (error) {
    console.log("Transaction failed: ", e);
    throw e
  }
}

export const updateUserProfile = async (uid, updatedObj) => {
  try {
    await runTransaction(db, async (transaction) => {
      const userDocRef = doc(db, `users/${uid}`)
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        throw "Document does not exist!";
      }
      transaction.update(userDocRef, { ...updatedObj });
    });
    console.log("Transaction successfully committed!");
    return true
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false
  }
}

export const getProfileUpdateRequests = async (uid) => {
  console.log("getProfileUpdateRequests", uid)
  return await getDoc(doc(db, `users_edit/${uid}`))
  .then(doc => {
    const docData = doc.data()
    return {
      id: doc.id,
      ...docData
    }
  })
}


export const checkSubdomainExist = async (subdomainName) => {
  try {
    return await getDoc(doc(db, `registered_subdomains/${subdomainName}`))
  } catch (error) {
    console.log("checkSubdomainExist", error)
    return false
  }
  
}

export const saveAccountDeleteRequest = async (data) => {
  try {
    await addDoc(collection(db, "account_delete_request"), data);
    return true
  } catch (e) {
    console.log("saveAccountDeleteRequest failed: ", e);
    return false
  }
}

export const getReportFlag = async (reportID) => {
  try {
    let data = []
    await getDocs(collection(db, `reportFlag/${reportID}/user`))
      .then(querySnap => {
        querySnap.forEach(doc => {
          data.push({
            id: doc.id,
            ...doc.data()
          })
        });
      })
    return data
  } catch (error) {
    console.log(error)
    return []
  }


  // try {
  //   return await getDoc(doc(db, `reportFlag/${reportID}/user`))
  // } catch (error) {
  //   console.log("getReportFlag Error", error)
  //   return false
  // }
}