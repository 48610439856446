import React, { useEffect, useState } from "react"

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Clear';

// Otis Admin PRO React context
import { useMaterialUIController } from "context";
import { updateReportTags } from "services/firebaseServices"

const AddReportTags = ({
  reportTags=[],
  id,
  updateReport,
  ...rest
}) => {
  const [controller] = useMaterialUIController();
  const { allReportTags } = controller;
  const [selectedTags, setSelectedTags] = useState(reportTags)
  const [isEdited, setIsEdited] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const toggleSelect = (tag) => {
    if(selectedTags.includes(tag.id)) {
      setSelectedTags(selectedTags.filter(tid => tid != tag.id))
    } else {
      setSelectedTags([...selectedTags, tag.id])
    }
    setIsEdited(true)
  }

  const onSaveTagsForm = () => {
    console.log("selectedTags", selectedTags)
    setIsSaving(true)
    try {
      updateReportTags(id, selectedTags)
      .then(() => {
        updateReport(selectedTags)
        setIsEdited(false)
        setIsSaving(false)
      }, error => {
        alert(error.message)
        setIsEdited(false)
        setIsSaving(false)
      })
    } catch (error) {
      
    }
  }
  const onCancelTagsForm = () => {
    setSelectedTags(reportTags)
    setIsEdited(false)
  }

  return (
    <MDBox pt={3}>
      <MDTypography variant="body2" fontWeight="regular" color="text">Choose tag(atleast one)</MDTypography>
      <Stack direction="row" alignItems={'center'} sx={{flexWrap: 'wrap', gap: '5px'}}>
        {allReportTags.map(tag => {
          const isSelected = selectedTags.includes(tag.id)
          return (
            <Chip
              key={tag.name}
              label={tag.name}
              onClick={() => toggleSelect(tag)}
              color={isSelected? "info": 'default'}
              variant={isSelected? "filled" : "outlined"}
              onDelete={() => console.log("onDelete")}
              deleteIcon={isSelected? <DoneIcon /> : <CancelIcon /> }
            />
          )}
        )}
      </Stack>
      {isEdited &&
        <>
          <IconButton aria-label="Save" onClick={onSaveTagsForm} disabled={isSaving}>
            <DoneIcon />
          </IconButton>
          <IconButton aria-label="Cancel" onClick={onCancelTagsForm} disabled={isSaving}>
            <CancelIcon />
          </IconButton>
        </>
        }
    </MDBox>
  )
}

export default AddReportTags