import { createContext, useContext, useReducer } from "react";

// The Kanban main context
const Kanban = createContext();

function reducer(state, action) {
  switch (action.type) {
    case "ADD_USER": {
      state.users[action.value.uid] = {...action.value }
      return { 
        ...state, 
        users: {
          ...state.users,
        }
      };
    }

    case "SET_REPORTS": {
      return { 
        ...state, 
        reports: [ ...action.value ],
        havingNewReports: false
      };
    }

    case "SET_LATEST_REPORTS": {
      return { 
        ...state, 
        newReports: [ ...action.value ],
        havingNewReports: true
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Kanban context provider
function KanbanProvider({ children }) {
  const initialState = {
    users: {},
    reports: [],
    havingNewReports: false,
    newReports: []
  }

  const [stateKanban, dispatchKanban] = useReducer(reducer, initialState);
  const contextValue = [stateKanban, dispatchKanban];
  return <Kanban.Provider value={contextValue}>{children}</Kanban.Provider>;
}

// Timeline custom hook for using context
function useKanban() {
  const context = useContext(Kanban);

  if (!context) {
    throw new Error(
      "useKanban should be used inside the KanbanProvider."
    );
  }

  return context;
}

const addUser = (dispatch, value) => dispatch({ type: "ADD_USER", value });
const setReports = (dispatch, value) => dispatch({ type: "SET_REPORTS", value });
const setLatestReports = (dispatch, value) => dispatch({ type: "SET_LATEST_REPORTS", value });

export { KanbanProvider, useKanban, addUser, setReports, setLatestReports };
/* eslint-enable react/prop-types */
