import { useState, useEffect, useCallback } from "react";

// react-images-viewer components
import ImageViewer from "react-simple-image-viewer";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import Stack from "@mui/material/Stack";

function IdentityDocuments({user}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    user.identityDocument,
    user.identitySelfie
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <MDBox mb={3}>
      <MDBox m={2} pt={1}>
        <Stack direction="row" spacing={3}>
          {images.map((src, index) => (
            <MDBox
              component="img"
              src={ src }
              onClick={ () => openImageViewer(index) }
              key={ index }
              borderRadius="lg"
              shadow="md"
              width="100%"
              height="10rem"
              minHeight="5rem"
              sx={{ cursor: "pointer", objectFit: "cover" }}
            />
          ))}
        </Stack>
      </MDBox>

      {isViewerOpen && (
        <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
        />
      )}
    </MDBox>
  )
}

export default IdentityDocuments