import React, { useEffect, useState, useRef, useMemo } from "react"

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Carousel from 'react-material-ui-carousel'

import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";

// Otis Admin PRO React context
import { useMaterialUIController } from "context";
import { useKanban } from "layouts/dashboards/kanban/context"

import { toggleVettedReport, updateTimeline } from "services/firebaseServices"

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import FormField from "components/FormField";
import MDSnackbar from "components/MDSnackbar";

import ReportFlagItem from "./components/ReportFlagItem";
import ReportIdDialog from "layouts/dashboards/kanban/components/CardDetail/components/ReportIdDialog";
import TimelineView from "layouts/dashboards/kanban/components/CardDetail/components/TimelineView";
import AddReportTags from "layouts/dashboards/kanban/components/CardDetail/components/AddReportTags";
import defaultAvatar from "assets/images/defaultAvatar.png"
import LTTGoogleMap from "components/LTTGoogleMap";
import { getReportFlag } from "services/firebaseServices";

const notesInternalInitialValues = { notesInternal: '' }
const notesIntValds = Yup.object().shape({
  notesInternal: Yup.string().required("This field is required").max(5000)
})

const notesExternalInitialValues = { notesExternal: '' }
const notesExtValds = Yup.object().shape({
  notesExternal: Yup.string().required("This field is required").max(5000)
})

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.warning
      : theme.palette.gradients.dark.main,
  borderRadius: '8px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: "#fff"
  }
}));


function CardDetail({ data, openDetail, handleCloseDetail, handleMoveCard, updateReport, defaultExpandPanel = "panel1" }) {
  const { id, images, issue, description, reportorUserId, createdAt, modifiedAt, municipal, viewedBy, reportUniqueId, geo, flaggedBy } = data
  const defaultProps = {
    center: {
      lat: geo?._latitude ?? 10.11,
      lng: geo?._longitude ?? 6.21
    },
    zoom: 9
  }
  const [controller] = useMaterialUIController();
  const [stateKanban] = useKanban();
  const { users } = stateKanban
  const { municipals, user, allReportTags } = controller;
  const [isVetted, setIsVetted] = useState(!!data.isVetted)
  const [timeline, setTimeline] = useState(data.timeline)
  const [reportTags, setReportTags] = useState(data.reportTags)
  const [expanded, setExpanded] = useState(defaultExpandPanel);
  const [openReportIdDialog, setOpenReportIdDialog] = useState(false);
  const [moveMenuEl, setMoveMenuEl] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [reportFlagData, setReportFlagData] = useState([])

  useEffect(() => {
    getReportFlagData()
  }, [])

  const getReportFlagData = async () => {
    const reportFlags = await getReportFlag(id)
    setReportFlagData(reportFlags)
  }
  // console.log("reportFlagData", reportFlagData)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const municipalName = municipals.find(m => m.id === municipal)?.long_name

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openDetail) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDetail]);

  const columnName = useMemo(() => {
    return timeline[timeline.findLastIndex(t => t.status === "COMPLETED")].title
  }, [timeline])

  const toggleSnackbar = () => setShowSnackbar(!showSnackbar)

  const handleIsVetted = async (e) => {
    e.stopPropagation();
    let isVetted2 = !isVetted

    /* 
      * check validation before vetting report
      * atleast one tag associated with the report to make it vetted
    */
    if (!!reportTags === false || reportTags.length === 0) {
      setShowSnackbar(true)
      // alert("Please add report tag to report first.")
      return false
    }

    setIsVetted(isVetted2)
    try {
      toggleVettedReport(id, isVetted2)
        .then(() => {
          updateReport(id, {
            ...data,
            isVetted: isVetted2
          })
        }, error => {
          alert(error.message)
          setIsVetted(false)
        })

    } catch (error) {
      console.log(error)
    }
  }

  const handleNotesExternalSubmit = async (values, action) => {
    // console.log("handleNotesExternalSubmit", values)
    let index = timeline.findLastIndex(t => t.status === "COMPLETED")
    let modifiedTimeline = [...timeline]
    let previousExternal = modifiedTimeline[index]?.external || {}
    let externalHistory = modifiedTimeline[index]?.external?.history || []
    let latestMessage = {
      ...previousExternal,
      history: [
        ...externalHistory,
        {
          message: values.notesExternal,
          createdAt: new Date().getTime(),
          agentId: user.uid
        }
      ]
    }
    modifiedTimeline[index].external = latestMessage
    const response = await updateTimeline(id, modifiedTimeline)
    if (response) {
      setTimeline(modifiedTimeline)
      action.resetForm()
    }
  }

  const handleNotesInternalSubmit = async (values, action) => {
    // console.log("handleNotesInternalSubmit", values)
    let index = timeline.findLastIndex(t => t.status === "COMPLETED")
    let modifiedTimeline = [...timeline]
    let previousInternal = modifiedTimeline[index]?.internal || {}
    let internalHistory = modifiedTimeline[index]?.internal?.history || []
    let latestMessage = {
      ...previousInternal,
      history: [
        ...internalHistory,
        {
          message: values.notesInternal,
          createdAt: new Date().getTime(),
          agentId: user.uid
        }
      ]
    }
    modifiedTimeline[index].internal = latestMessage
    const response = await updateTimeline(id, modifiedTimeline)
    if (response) {
      setTimeline(modifiedTimeline)
      action.resetForm()
    }
  }

  const handleTimelineEdit = async (modifiedTimeline, cb) => {
    // console.log("handleTimelineEdit", modifiedTimeline)
    const response = await updateTimeline(id, modifiedTimeline)
    if (response) {
      setTimeline(modifiedTimeline)
      cb(true, false)
    } else {
      cb(false, "Something went wrong!")
    }
  }

  const handleDeleteAction = async (modifiedTimeline, cb) => {
    // console.log("handleDeleteAction", modifiedTimeline)

    const response = await updateTimeline(id, modifiedTimeline)
    if (response) {
      setTimeline(modifiedTimeline)
      cb(true, false)
    } else {
      cb(false, "Something went wrong!")
    }
  }

  const onOpenReportIdDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenReportIdDialog(true)
  }

  // const renderMenuOptionsBAK = () => {
  //   let menuItems = ['Reviewing', 'Assigned', 'In Progress', 'Completed']
  //   return (
  //     menuItems.filter(i=>i!==columnName).map((item, index) => {
  //       const onMove = () => {
  //         let fromColumn = columnName.toLowerCase().replace(" ", "")
  //         let toColumn = item.toLowerCase().replace(" ", "")
  //         handleMoveCard(data, {fromPosition: 0,fromColumnId: fromColumn}, {toPosition: 0, toColumnId: toColumn})
  //         handleMoveMenuClose()
  //         handleCloseDetail()
  //       }
  //       return (
  //         <MenuItem key={index} onClick={onMove}>{item}</MenuItem>
  //       )
  //     })
  //   )
  // }
  const renderMenuOptions = () => {
    let menuItems = ['Reviewing', 'Assigned', 'In Progress', 'Completed']
    return (
      menuItems.map((item, index) => {
        // const onMove = () => {
        //   let fromColumn = columnName.toLowerCase().replace(" ", "")
        //   let toColumn = item.toLowerCase().replace(" ", "")
        //   handleMoveCard(data, {fromPosition: 0,fromColumnId: fromColumn}, {toPosition: 0, toColumnId: toColumn})
        //   handleMoveMenuClose()
        //   handleCloseDetail()
        // }
        return (
          <MenuItem key={index} value={item}>{item}</MenuItem>
        )
      })
    )
  }

  const handleReportStatusChange = (event) => {
    const item = event.target.value
    // console.log("handleReportStatusChange", event.target.value)
    // return false
    let fromColumn = columnName.toLowerCase().replace(" ", "")
    let toColumn = item.toLowerCase().replace(" ", "")
    handleMoveCard(data, { fromPosition: 0, fromColumnId: fromColumn }, { toPosition: 0, toColumnId: toColumn })
    // handleMoveMenuClose()
    handleCloseDetail()
  }

  const handleMoveMenuOpen = (event) => {
    setMoveMenuEl(event.currentTarget);
  }
  const handleMoveMenuClose = () => {
    setMoveMenuEl(null)
  }

  return (
    <Dialog
      open={openDetail}
      onClose={handleCloseDetail}
      scroll={"paper"}
      fullWidth
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="#000" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ background: '#ffffff' }}
            >
              <MDBox sx={{ width: '100%' }}>
                <MDBox display="flex" alignItems="center" justifyContent="space-between" mx={2} ml={0}>
                  <MDTypography variant="h4" >{municipalName}</MDTypography>
                </MDBox>
                {/* <MDBox sx={{width: '100%', height: '2px', marginBottom: '5px', background: '#42424a'}}></MDBox> */}
                {/* <MDBox sx={{width: '100%', height: '2px', marginTop: '5px', background: '#42424a'}}></MDBox> */}
              </MDBox>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox>
                <MDBox mb={4}>
                  <Carousel
                    autoPlay={false}
                    animation={'slide'}
                    indicators={false}
                  >
                    {images.map((item, i) => {
                      return (
                        <MDBox key={`img-${i}`} sx={{ textAlign: 'center' }} onClick={() => window.open(item, "_blank")}>
                          <MDBox sx={{ maxWidth: '90%', margin: '0 auto', backgroundColor: '#f0f2f5' }}>
                            <img src={item} width="auto" style={{ cursor: 'pointer', maxHeight: '500px' }} />
                          </MDBox>
                        </MDBox>
                      )
                    })}
                  </Carousel>
                </MDBox>

                <MDBox display="flex" justifyContent="space-between">
                  <MDBox>
                    <MDBox
                      display="flex"
                      // justifyContent="space-between"
                      alignItems="center"
                      pt={0.5}
                      pb={3}
                      px={0}
                      lineHeight={1}
                    >
                      <MDBox
                        display="flex"
                        alignItems="center"
                      >
                        <MDAvatar
                          key={`img-${reportorUserId}`}
                          src={users[reportorUserId].profilePicUrl ? users[reportorUserId].profilePicUrl : defaultAvatar}
                          alt={users[reportorUserId] ? `${users[reportorUserId].firstName} ${users[reportorUserId].lastName}` : ""}
                          size="sm"
                          sx={{
                            border: ({ borders: { borderWidth }, palette: { white } }) =>
                              `${borderWidth[2]} solid ${white.main}`,
                            cursor: "pointer",
                            position: "relative",
                            ml: 0,
                            mr: 1,

                            "&:hover, &:focus": {
                              zIndex: "10",
                            },
                          }}
                        />
                        <MDTypography variant="body2" fontWeight="regular" color="text">
                          {users[reportorUserId] ? `${users[reportorUserId].firstName} ${users[reportorUserId].lastName}` : ""}
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2" fontWeight="regular" color="text" mx={2}>{'|'}</MDTypography>
                      <MDTypography variant="body2" fontWeight="regular" color="text">
                        {moment(Number(createdAt)).fromNow('y')}
                      </MDTypography>
                      <MDBox color="text" display="flex" alignItems="center">
                        {users[reportorUserId] && modifiedAt &&
                          <Icon color="inherit" sx={{ m: 0.5, ml: 2 }}>
                            edit
                          </Icon>
                        }
                        {/* <MDBadge size="xs" color={"dark"} badgeContent={"RDLG"} container /> */}
                      </MDBox>
                    </MDBox>

                    <MDTypography variant="body2" color="text">
                      <>
                        <b>{issue}</b>{': '}
                        {description}
                      </>
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDBox width="200px" height="100px" borderRadius="10px" overflow="hidden" >
                      <a href={`https://www.google.com/maps/search/?api=1&query=${geo._latitude}%2C${geo._longitude}`} target="_blank">
                        <LTTGoogleMap location={defaultProps} />
                        {/* <GoogleMap
                        defaultZoom={9}
                        defaultCenter={defaultProps.center}
                      >
                        <Marker position={defaultProps.center} />
                      </GoogleMap> */}
                        {/* <GoogleMapReact
                          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
                          defaultCenter={defaultProps.center}
                          defaultZoom={defaultProps.zoom}
                        >
                          <MDBox lat={defaultProps.center._latitude} lng={defaultProps.center._longitude} text="Location">
                            <img src={mapPin} width={10} height={15} />
                          </MDBox>
                        </GoogleMapReact> */}
                      </a>
                    </MDBox>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center" justifyContent="space-between" mt={3}>
                  <MDBox display="flex" alignItems="center">
                    <Icon>
                      {"visibility"}
                    </Icon>
                    <MDTypography variant="normal" color="text" ml={1}>
                      {viewedBy.length}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={1} >
                    <Switch checked={isVetted} onChange={handleIsVetted} disabled={user.role === "VIEWER"} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleIsVetted}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Vetted
                    </MDTypography>
                  </MDBox>
                  {user.role !== "VIEWER" ?
                    <MDBox display="flex" alignItems="center" sx={{ width: '100px', height: '50px' }}>
                      <FormControl fullWidth sx={{ height: '100%' }}>
                        <InputLabel id="report-status-label">Status</InputLabel>
                        <Select
                          labelId="report-status-label"
                          id="report-status-select"
                          value={columnName}
                          label="Status"
                          sx={{ height: '100%' }}
                          onChange={handleReportStatusChange}
                        >
                          {renderMenuOptions()}
                        </Select>
                      </FormControl>

                      {/* <MDButton
                      id="menu-button"
                      aria-controls={!!moveMenuEl ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={!!moveMenuEl ? 'true' : undefined}
                      onClick={handleMoveMenuOpen}
                    >
                      Move Card
                    </MDButton> */}
                      {/* <Menu
                      id="basic-menu"
                      anchorEl={moveMenuEl}
                      open={!!moveMenuEl}
                      onClose={handleMoveMenuClose}
                      MenuListProps={{
                        'aria-labelledby': 'menu-button',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {renderMenuOptions()}
                    </Menu> */}
                    </MDBox>
                    : <MDBox display="flex" alignItems="center" />}
                  <MDBox display="flex" alignItems="center" onClick={onOpenReportIdDialog}>
                    <MDButton variant="contained" color="info">Report ID</MDButton>
                  </MDBox>
                  <ReportIdDialog
                    open={openReportIdDialog}
                    handleClose={() => setOpenReportIdDialog(false)}
                    reportId={reportUniqueId}
                  />
                </MDBox>
                {user.role !== "VIEWER" ?
                  <AddReportTags
                    reportTags={reportTags}
                    id={id}
                    updateReport={(updateTags) => {
                      setReportTags(updateTags)
                      updateReport(
                        id,
                        {
                          ...data,
                          reportTags: updateTags
                        }
                      )
                    }}
                  />
                  :
                  (!!reportTags && reportTags.length > 0) ?
                    <MDBox pt={3}>
                      <Stack direction="row" spacing={1} alignItems={'center'}>
                        {reportTags.map(tag => {
                          return (
                            <Chip
                              key={tag}
                              label={allReportTags.find(t => t.id === tag)?.name ?? ""}
                              color={"info"}
                              variant={"filled"}
                            />
                          )
                        }
                        )}
                      </Stack>
                    </MDBox>
                    : null
                }
              </MDBox>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              sx={{ background: '#ffffff' }}
            >
              <MDBox sx={{ width: '100%' }}>
                <MDBox display="flex" alignItems="center" justifyContent="space-between" mx={2} ml={0}>
                  <MDTypography variant="h4">{"Notes (External)"}</MDTypography>
                </MDBox>
                {/* <MDBox sx={{width: '100%', height: '2px', marginBottom: '5px', background: '#42424a'}}></MDBox> */}
                {/* <MDBox sx={{width: '100%', height: '2px', marginTop: '5px', background: '#42424a'}}></MDBox> */}
              </MDBox>
            </AccordionSummary>
            <AccordionDetails>
              {user.role !== "VIEWER" ?
                <Formik
                  initialValues={notesExternalInitialValues}
                  validationSchema={notesExtValds}
                  onSubmit={handleNotesExternalSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form id="notesExternalForm" autoComplete="off">
                      <MDBox mb={2}>
                        <FormField
                          label="Type here..."
                          name="notesExternal"
                          value={values.notesExternal}
                          error={errors.notesExternal && touched.notesExternal}
                          success={values.notesExternal.length > 0 && !errors.notesExternal}
                          multiline
                          rows={5}
                        />
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end">
                        <MDButton variant="contained" type="submit" disabled={isSubmitting} color="info">Save</MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
                : null}

              {/* Timeline of External notes */}
              <TimelineView
                timeline={timeline}
                type="external"
                isEditable={user.role !== "VIEWER"}
                submitEditAction={handleTimelineEdit}
                deleteAction={handleDeleteAction}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              sx={{ background: '#ffffff' }}
            >
              <MDBox sx={{ width: '100%' }}>
                <MDBox display="flex" alignItems="center" justifyContent="space-between" mx={2} ml={0}>
                  <MDTypography variant="h4">{"Notes (Internal)"}</MDTypography>
                </MDBox>
                {/* <MDBox sx={{width: '100%', height: '2px', marginBottom: '5px', background: '#42424a'}}></MDBox> */}
                {/* <MDBox sx={{width: '100%', height: '2px', marginTop: '5px', background: '#42424a'}}></MDBox> */}
              </MDBox>
            </AccordionSummary>
            <AccordionDetails>
              {user.role !== "VIEWER" ?
                <Formik
                  initialValues={notesInternalInitialValues}
                  validationSchema={notesIntValds}
                  onSubmit={handleNotesInternalSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form id="notesInternalForm" autoComplete="off">
                      <MDBox mb={2}>
                        <FormField
                          label="Type here..."
                          name="notesInternal"
                          value={values.notesInternal}
                          error={errors.notesInternal && touched.notesInternal}
                          success={values.notesInternal.length > 0 && !errors.notesInternal}
                          multiline
                          rows={5}
                        />
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end">
                        <MDButton variant="contained" type="submit" disabled={isSubmitting} color="info">Save</MDButton>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
                : null}

              {/* Timeline of Internal notes */}
              <TimelineView
                timeline={timeline}
                type="internal"
                isEditable={user.role !== "VIEWER"}
                submitEditAction={handleTimelineEdit}
                deleteAction={handleDeleteAction}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{ background: '#ffffff' }}
            >
              <MDBox sx={{ width: '100%' }}>
                <MDBox display="flex" alignItems="center" justifyContent="space-between" mx={2} ml={0}>
                  <MDTypography variant="h4">{"Report Flags"}</MDTypography>
                </MDBox>
                {/* <MDBox sx={{width: '100%', height: '2px', marginBottom: '5px', background: '#42424a'}}></MDBox> */}
                {/* <MDBox sx={{width: '100%', height: '2px', marginTop: '5px', background: '#42424a'}}></MDBox> */}
              </MDBox>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox color="text" display="flex" sx={{ flexDirection: 'column', margin: '0 10px' }}>
                {!!reportFlagData?.length &&
                  reportFlagData.map((item, index) =>
                    <ReportFlagItem item={item} index={index} />
                  )
                }
              </MDBox>
            </AccordionDetails>
          </Accordion>

          <MDSnackbar
            color="error"
            icon="close"
            title="Validation Error"
            content="Please add tag(s) to report first."
            open={showSnackbar}
            close={toggleSnackbar}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default CardDetail;