import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { useTheme } from '@mui/material/styles';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import FormField from "components/FormField";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { logoutUser } from "services/userServices"

import { useMaterialUIController } from "context";

// Images
import bgImage from "assets/images/bg-sign-in-new.jpeg";
import logo from "assets/images/logo.png";
import { saveAccountDeleteRequest } from "services/firebaseServices";

const initialValues={ email: '', phoneNumber: '', password: '', name: '' }
const validations = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("This field is required"),
  phoneNumber: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
  name: Yup.string().required("This field is required")
})

function PrivacyPolicy() {
  const theme = useTheme();
  const [controller, dispatch] = useMaterialUIController();
  const { tenant } = controller;
  const [rememberMe, setRememberMe] = useState(false);
  const [showError, setShowError] = useState(false)

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (values, actions) => {
    // console.log("handleSubmit tenant", tenant)
    const auth = getAuth()
    signInWithEmailAndPassword(auth, values.email, values.password)
    .then(async (userCredential) => {
      console.log("userCredential", userCredential);
      
      let data = {...values}
      delete data.password
      const response = await saveAccountDeleteRequest(data)
      if(response) {
        actions.resetForm()
        alert("Your delete request has been registered with us. Our team will take action on it soon and contact you on provided email.")
      } else {
        setShowError("Something went wrong. Please try again.")
      }
      actions.resetForm()
      logoutUser()
    })
    .catch(error => {
      alert("Invalid user request")
    })
    actions.setSubmitting(false)
  }

  const bgIMage = tenant? tenant.bg_image : bgImage
  const tenantLogo = tenant? tenant.logo : logo

  return (
    <BasicLayout image={bgIMage} requestForm>
      <MDBox>
        <MDBox sx={{width: '150px', height: '100px', position: 'relative', margin: '0 auto', marginBottom: '40px'}}>
          <img src={tenantLogo} style={{width: '100%', height: '100%'}} />
        </MDBox>
      </MDBox>
      
      {!!showError &&
        <MDAlert color="error" dismissible onDismiss={() => setShowError(false)}>
          <MDTypography variant="body2" color="white">
            {showError}
          </MDTypography>
        </MDAlert>
      }
    </BasicLayout>
  );
}

export default PrivacyPolicy;
