import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MDButton from "components/MDButton";

function ReportIdDialog({open, handleClose, reportId}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Report ID"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {reportId}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose} autoFocus color={"info"}>
          Ok
        </MDButton>
      </DialogActions>
    </Dialog>
  )
}

export default ReportIdDialog