// React context
import { KanbanProvider } from "layouts/dashboards/kanban/context"

import Reports from "./Reports";

function Kanban() {

  return (
    <KanbanProvider>
      <Reports />
    </KanbanProvider>
  );
}

export default Kanban;