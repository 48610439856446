import React, { useEffect, useCallback, useState, useMemo } from "react"

// @mui material components
import Icon from "@mui/material/Icon";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import moment from "moment";

// Otis Admin PRO React context
import { useMaterialUIController } from "context";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import MDAvatar from "components/MDAvatar";

import { getUserInfo } from "services/firebaseServices"

import { useKanban, addUser } from "layouts/dashboards/kanban/context"
import { textEllipses }from "utils/Utils"
import defaultAvatar from "assets/images/defaultAvatar.png"

function Card({ data, handleClick, columnIndex, handleMoveCard }) {
  const { images, issue, description, timeline, bookmarkBy, reportorUserId, createdAt, modifiedAt, municipal, reportTags, flaggedBy } = data
  const [stateKanban, dispatchKanban] = useKanban()
  const { users } = stateKanban;
  const [controller] = useMaterialUIController();
  const { allReportTags, user } = controller;
  const [reportor, setReportor] = useState(null)
  const [isVetted, setIsVetted] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)

  const isFlagged = !!flaggedBy && flaggedBy.length > 0

  const columnName = useMemo(() => {
    return timeline[timeline.findLastIndex(t=>t.status === "COMPLETED")].title
  }, [timeline])

  const getReportUser = useCallback(async () => {
    if(!!users[reportorUserId] === false) {
      const userSnap = await getUserInfo(reportorUserId)
      if(userSnap.exists) {
        setReportor(userSnap.data())
        addUser(dispatchKanban, userSnap.data())
      }
    } else {
      console.log("Save request!")
      setReportor(users[reportorUserId])
    }
  }, [reportorUserId])

  useEffect(() => {
    getReportUser()
  }, [])

  const handleIsVetted = (e) => {
    e.stopPropagation();
    setIsVetted(!isVetted)
  }


  const openDetailView = (e) => {
    handleClick(data)
  }

  const calculateProgress = () => {
    let progress = 0
    timeline.map(t => {
      if(t.status === "COMPLETED") progress += 25
    })
    return progress
  }

  const renderMenuOptions = () => {
    let menuItems = ['Reviewing', 'Assigned', 'In Progress', 'Completed']
    return (
      menuItems.map((item, index) => {
        return (
          <MenuItem key={index} value={item}>{item}</MenuItem>
        )
      })
    )
  }

  const handleReportStatusChange = (event) => {
    const item = event.target.value
    // console.log("handleReportStatusChange", event.target.value)
    // return false
    let fromColumn = columnName.toLowerCase().replace(" ", "")
    let toColumn = item.toLowerCase().replace(" ", "")
    handleMoveCard(data, {fromPosition: 0,fromColumnId: fromColumn}, {toPosition: 0, toColumnId: toColumn})
    // handleMoveMenuClose()
  }

  const showContactInfo = (e) => {
    e.stopPropagation()
    handleInfoClose()
  }

  const handleInfoClose = () => {
    setOpenInfo(!openInfo)
  }

  return (
    <MDBox key={data.id} onClick={openDetailView} sx={{background: `url(${images[0]})`, backgroundPosition: 'center', backgroundSize: 'cover'}} >
      <MDBox sx={{width: "100%", background: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center', backdropFilter:'blur(5px)'}}>
        {images && <MDBox component="img" src={images[0]} maxWidth="100%" width="50%" height="200px" />}
      </MDBox>
      <MDBox sx={{ background: '#fff' }}>
        <MDBox
          display="flex"
          // justifyContent="space-between"
          alignItems="center"
          pt={0.5}
          pb={0}
          px={0}
          lineHeight={1}
        >
          <MDBox
            display="flex"
            alignItems="center"
          >
            <MDAvatar
              key={`img-${reportorUserId}`}
              src={(reportor && reportor.profilePicUrl)? reportor.profilePicUrl : defaultAvatar}
              alt={reportor? `${reportor.firstName} ${reportor.lastName}` : ""}
              size="sm"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",
                ml: 0,
                mr: 1,

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
            <MDTypography variant="body2" fontWeight="regular" color="text" sx={{fontSize: '0.8rem', lineHeight: '1.2rem'}}>
              {reportor? `${reportor.firstName} ${reportor.lastName}` : ""}
            </MDTypography>
          </MDBox>

          <MDTypography variant="body2" fontWeight="regular" color="text" mx={1} sx={{fontSize: '0.8rem', lineHeight: '1.2rem'}}>{'|'}</MDTypography>

          <MDTypography variant="body2" fontWeight="regular" color="text" sx={{fontSize: '0.8rem', lineHeight: '1.2rem'}}>
            {moment(Number(createdAt)).fromNow('y')}
          </MDTypography>

          <MDBox color="text" display="flex" alignItems="center" mx={1}>{`|`}</MDBox>

          {/* <MDBox color="text" display="flex" alignItems="center">{data.timeline[columnIndex].title}</MDBox> */}
          <MDBox color="text" display="flex" alignItems="center">
            {user.role !== "VIEWER" ?
              <MDBox display="flex" alignItems="center" sx={{width: '100px', height: '50px'}}>
                {/* <FormControl fullWidth sx={{height: '100%'}}>
                  <InputLabel id="report-status-label">Status</InputLabel> */}
                  <Select
                    value={columnName}
                    onChange={handleReportStatusChange}
                    onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
                  >
                    {renderMenuOptions()}
                  </Select>
                {/* </FormControl> */}
              </MDBox>
              : <MDBox display="flex" alignItems="center" />}
          </MDBox>

          <MDBox color="text" display="flex" alignItems="center">
            {reportor && modifiedAt  &&
              <Icon color="inherit" sx={{ m: 0.5 }}>
                edit
              </Icon>
            }
            {/* <MDBadge size="xs" color={"dark"} badgeContent={"RDLG"} container /> */}
          </MDBox>
          {(reportor?.email || reportor?.phoneNumber) &&
            <MDBox color="text" display="flex" alignItems="center" onClick={showContactInfo}>
              <Icon color="inherit" sx={{ m: 0.5 }}>
                contact_phone
              </Icon>
            </MDBox>
          }

          <MDBox color="text" display="flex" alignItems="center">
            {isFlagged  &&
              <Icon color="inherit" sx={{ m: 0.5 }}>
                flag
              </Icon>
            }
          </MDBox>

        </MDBox>
        <MDBox>
          <MDTypography variant="body2" color="text" sx={{fontSize: '0.8rem', lineHeight: '1.2rem'}}>
            <>
              <b>{issue}</b>{': '}
              {textEllipses(description, 200)}
            </>
          </MDTypography>
          
          {(!!reportTags && reportTags.length > 0) ? 
            <MDBox mt={0.25}>
              {/* <MDProgress variant="gradient" value={calculateProgress()} color={"dark"} /> */}
              <Stack direction="row" spacing={1} alignItems={'center'}>
                {reportTags.map(tag => {
                  return (
                    <Chip
                      key={tag}
                      label={allReportTags.find(t => t.id === tag)?.name ?? ""}
                      color={"info"}
                      variant={"filled"}
                      size="small"
                    />
                  )}
                )}
              </Stack>
            </MDBox>
            : null
          }
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center" color="text">
            
          </MDBox>
        </MDBox>
      </MDBox>
      <Dialog onClose={handleInfoClose} open={openInfo}>
        <DialogTitle>
          {"Email/Phone number"}
        </DialogTitle>
        <DialogContent>
          <MDBox sx={{display: 'flex', flexDirection: 'row'}}>
            <MDTypography>Email: </MDTypography>
            <MDTypography sx={{marginLeft: '5px'}}>{reportor?.email ?? "N/A"}</MDTypography>
          </MDBox>
          <MDBox sx={{display: 'flex', flexDirection: 'row'}}>
            <MDTypography>Phone Number: </MDTypography>
            <MDTypography sx={{marginLeft: '5px'}}>{reportor?.phoneNumber ?? "N/A"}</MDTypography>
          </MDBox>
        </DialogContent>
      </Dialog>
    </MDBox>
  );
}

export default Card;
