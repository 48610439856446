
/** 
  All of the routes for the Otis Admin PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Otis Admin PRO React layouts
// import Analytics from "layouts/dashboards/analytics";
import Kanban from "layouts/dashboards/kanban";
import Broadcast from "layouts/dashboards/Broadcast";
import Users from "layouts/dashboards/users";
import UserDetail from "layouts/dashboards/userDetail";
import SignIn from "layouts/authentication/sign-in";
import UserDeleteDataRequest from "layouts/authentication/user-delete-data-request";
import PrivacyPolicy from "layouts/public/PrivacyPolicy";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboard",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   collapse: [
  //   ],
  // },
  // {
  //   name: "Analytics",
  //   key: "analytics",
  //   route: "/dashboard/analytics",
  //   isPrivate: true,
  //   type: "menuLink",
  //   component: <Analytics />,
  // },
  {
    name: "Reports",
    key: "kanban",
    route: "/dashboard/kanban",
    isPrivate: true,
    type: "menuLink",
    component: <Kanban />,
  },
  {
    name: "Broadcast",
    key: "broadcast",
    route: "/dashboard/broadcast",
    isPrivate: true,
    type: "menuLink",
    accessRoles: ['ADMIN', 'AGENT'],
    component: <Broadcast />,
  },
  {
    name: "Users",
    key: "Users",
    route: "/dashboard/users",
    isPrivate: true,
    type: "menuLink",
    accessRoles: ['ADMIN', 'AGENT'],
    component: <Users />,
  },
  {
    type: "title",
    name: "SignIn",
    key: "signin",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "title",
    name: "User Account Delete Request",
    key: "userDataDelete",
    route: "/account/data-delete-request",
    component: <UserDeleteDataRequest />,
  },
  {
    name: "Privacy Policy",
    key: "privacyPolicy",
    route: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    name: "UsersDetails",
    key: "UsersDetails",
    route: "/dashboard/users/:key",
    isPrivate: true,
    accessRoles: ['ADMIN', 'AGENT'],
    component: <UserDetail />,
  },
];

export default routes;
