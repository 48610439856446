/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { sessionTypes, municipalTypes } from "./actionTypes";

// The Soft UI Dashboard PRO Material main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Otis Admin PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }

    // session actions
    case sessionTypes.LOAD_SESSION: {
      return { 
        ...state,
        user: {},
        isLoggedIn: null
      }
    }
    case sessionTypes.SET_SESSION_SUCCESS: {
      return { 
        ...state,
        user: {...action.payload},
        isLoggedIn: true
      }
    }
    case sessionTypes.SET_SESSION_OUT: {
      return { 
        ...state,
        user: {},
        isLoggedIn: false
      }
    }
    case sessionTypes.SET_TENANT: {
      return { 
        ...state,
        tenant: action.payload
      }
    }

    // Muniicipal action
    case municipalTypes.SET_MINICIPAL: {
      return { 
        ...state,
        municipals: action.payload
      }
    }
    case municipalTypes.SET_SELECTED_MINISTRIES: {
      return { 
        ...state,
        selectedMunicipal: action.payload
      }
    }
    case municipalTypes.SET_REPORT_TYPES_ISSUES: {
      return { 
        ...state,
        reportTypesIssues: action.payload
      }
    }
    case municipalTypes.SET_REPORT_TAGS: {
      return { 
        ...state,
        allReportTags: action.payload
      }
    }
    case municipalTypes.SET_SELECTED_REPORT_TYPE: {
      return { 
        ...state,
        selectedReportType: action.payload
      }
    }
    case municipalTypes.SET_SELECTED_REPORT_SUB_TYPE: {
      return { 
        ...state,
        selectedReportSubType: action.payload
      }
    }
    case municipalTypes.SET_SELECTED_GROUP_BY_FILTER: {
      return { 
        ...state,
        selectedGroupByFilter: action.payload
      }
    }
    case municipalTypes.SET_REPORT_ID_FILTER: {
      return { 
        ...state,
        reportIdFilter: action.payload
      }
    }
    case municipalTypes.SET_REPORT_FLAG_FILTER: {
      return { 
        ...state,
        reportFlagFilter: action.payload
      }
    }
    case municipalTypes.SET_REPORT_TAG_FILTER: {
      return { 
        ...state,
        reportTagFilter: action.payload
      }
    }
    case municipalTypes.SET_REPORT_DATE_FILTER: {
      return { 
        ...state,
        reportDateFilter: action.payload
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const sessionInitialState = {
  user: {},
  isLoggedIn: null,    // possible values: null= "session not established", false = "session out", true = "session exists"
  tenant: null
}

// Otis Admin PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: true,
    transparentSidenav: false,
    whiteSidenav: true,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,

    ...sessionInitialState,
    municipals: [],
    selectedMunicipal: null,
    reportTypesIssues: [],
    allReportTags: [],
    selectedReportType: null,
    selectedReportSubType: 0,
    selectedGroupByFilter: 0,
    reportIdFilter: 0,
    reportFlagFilter: 0,
    reportTagFilter: [],
    reportDateFilter: null,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const contextValue = [controller, dispatch];

  return <MaterialUI.Provider value={contextValue}>{children}</MaterialUI.Provider>;
}

// Otis Admin PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

const sessionSuccess = (dispatch, payload) => dispatch({ type: sessionTypes.SET_SESSION_SUCCESS, payload });
const sessionOut = (dispatch) => dispatch({ type: sessionTypes.SET_SESSION_OUT });
const loadSession = (dispatch) => dispatch({ type: sessionTypes.LOAD_SESSION });
const setTenant = (dispatch, payload) => dispatch({ type: sessionTypes.SET_TENANT, payload });

const setMunicipals = (dispatch, payload) => dispatch({ type: municipalTypes.SET_MINICIPAL,  payload});
const setSelectedMunicipal = (dispatch, payload) => dispatch({ type: municipalTypes.SET_SELECTED_MINISTRIES,  payload});

const setReportTypesIssues = (dispatch, payload) => dispatch({ type: municipalTypes.SET_REPORT_TYPES_ISSUES,  payload});
const setReportTags = (dispatch, payload) => dispatch({ type: municipalTypes.SET_REPORT_TAGS,  payload});
const setSelectedReportType = (dispatch, payload) => dispatch({ type: municipalTypes.SET_SELECTED_REPORT_TYPE,  payload});
const setSelectedReportSubType = (dispatch, payload) => dispatch({ type: municipalTypes.SET_SELECTED_REPORT_SUB_TYPE,  payload});
const setSelectedGroupByFilter = (dispatch, payload) => dispatch({ type: municipalTypes.SET_SELECTED_GROUP_BY_FILTER,  payload});
const setReportIdFilter = (dispatch, payload) => dispatch({ type: municipalTypes.SET_REPORT_ID_FILTER,  payload});
const setReportFlagFilter = (dispatch, payload) => dispatch({ type: municipalTypes.SET_REPORT_FLAG_FILTER,  payload});
const setReportTagFilter = (dispatch, payload) => dispatch({ type: municipalTypes.SET_REPORT_TAG_FILTER,  payload});
const setReportDateFilter = (dispatch, payload) => dispatch({ type: municipalTypes.SET_REPORT_DATE_FILTER,  payload});

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  sessionSuccess,
  sessionOut,
  loadSession,
  setTenant,
  setMunicipals,
  setSelectedMunicipal,
  setReportTypesIssues,
  setReportTags,
  setSelectedReportType,
  setSelectedReportSubType,
  setSelectedGroupByFilter,
  setReportIdFilter,
  setReportFlagFilter,
  setReportTagFilter,
  setReportDateFilter
};
