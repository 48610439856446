import React, { useState, useEffect } from "react"

// @mui material components
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { useKanban } from "layouts/dashboards/kanban/context"
import { getUserInfo } from "services/firebaseServices";
import { addUser } from "layouts/dashboards/kanban/context";
import defaultAvatar from "assets/images/defaultAvatar.png"

const ReportFlagItem = ({item, index}) => {
  const [stateKanban, dispatchKanban] = useKanban();
  const { users } = stateKanban
  const [reportFlagItem, setReportFlagItem] = useState({...item})

  useEffect(() => {
    getReportFlagUserDetails()
  }, [item])

  const getReportFlagUserDetails = async () => {
    const userId = item.id
    if(!!users[userId] === false) {
      const userSnap = await getUserInfo(userId)
      if(userSnap.exists) {
        setReportFlagItem({ ...item, user: userSnap.data() })
        // addUser(dispatchKanban, userSnap.data())
      }
    } else {
      setReportFlagItem({ ...item, user: users[userId] })
    }
  }

  return (
    <MDBox key={index} display="flex" alignItems="center" justifyContent="space-between" sx={{borderBottom: '1px solid #ccc', padding: '10px 0'}}>
      {/* <Icon color="inherit" sx={{ m: 0.5 }} fontSize="24px" >flag</Icon> */}
      <MDBox>
        <MDTypography variant="body2" fontWeight="regular" color="text">{reportFlagItem?.flag}</MDTypography>
      </MDBox>
      {!!reportFlagItem?.user &&
        <MDBox
          display="flex"
          alignItems="center"
        >
          <MDAvatar
            key={`img-${reportFlagItem.user.id}`}
            src={reportFlagItem.user.profilePicUrl? reportFlagItem.user.profilePicUrl : defaultAvatar}
            alt={`${reportFlagItem.user.firstName} ${reportFlagItem.user.lastName}`}
            size="sm"
            sx={{
              border: ({ borders: { borderWidth }, palette: { white } }) =>
                `${borderWidth[2]} solid ${white.main}`,
              cursor: "pointer",
              position: "relative",
              ml: 0,
              mr: 1,

              "&:hover, &:focus": {
                zIndex: "10",
              },
            }}
          />
          <MDTypography variant="body2" fontWeight="regular" color="text">
            {`${reportFlagItem.user.firstName} ${reportFlagItem.user.lastName} `}
          </MDTypography>
          <MDTypography sx={{marginRight: '10px'}} variant="body2" fontWeight="regular" color="text">{' '}</MDTypography>
        </MDBox>
      }
    </MDBox>
  )
}

export default ReportFlagItem