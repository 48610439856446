import React, { useEffect, useState, useRef, useMemo } from "react"

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Carousel from 'react-material-ui-carousel'

import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";

// Otis Admin PRO React context
import { useMaterialUIController } from "context";

import { toggleVettedReport, updateTimeline } from "services/firebaseServices"

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import FormField from "components/FormField";

import ReportIdDialog from "layouts/dashboards/kanban/components/CardDetail/components/ReportIdDialog";
import TimelineView from "layouts/dashboards/kanban/components/CardDetail/components/TimelineView";
import defaultAvatar from "assets/images/defaultAvatar.png"

const notesInternalInitialValues={ notesInternal: '' }
const notesIntValds = Yup.object().shape({
  notesInternal: Yup.string().required("This field is required").max(256)
})

const notesExternalInitialValues={ notesExternal: '' }
const notesExtValds = Yup.object().shape({
  notesExternal: Yup.string().required("This field is required").max(256)
})

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: 
    theme.palette.mode === 'dark'
    ? theme.palette.warning
    : theme.palette.gradients.dark.main,
  borderRadius: '8px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: "#fff"
  }
}));


function CardDetail({ data, userData, openDetail, handleCloseDetail, handleMoveCard, updateReport, defaultExpandPanel="panel1" }) {
  const { id, images, issue, description, reportorUserId, createdAt, modifiedAt, municipal, viewedBy, reportUniqueId } = data
  const [controller] = useMaterialUIController();
  const { municipals, user } = controller;
  const [isVetted, setIsVetted] = useState(!!data.isVetted)
  const [timeline, setTimeline] = useState(data.timeline)
  const [expanded, setExpanded] = useState(defaultExpandPanel);
  const [openReportIdDialog, setOpenReportIdDialog] = useState(false);
  const [moveMenuEl, setMoveMenuEl] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const municipalName = municipals.find(m => m.id === municipal)?.long_name

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openDetail) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDetail]);

  const columnName = useMemo(() => {
    return timeline[timeline.findLastIndex(t=>t.status === "COMPLETED")].title
  }, [timeline])

  const handleIsVetted = async (e) => {
    e.stopPropagation();
    let isVetted2 = !isVetted
    setIsVetted(isVetted2)
    try {
      toggleVettedReport(id, isVetted2)
      .then(() => {
        updateReport(id, {
          ...data,
          isVetted: isVetted2
        })
      }, error => {
        alert(error.message)
        setIsVetted(false)
      })
      
    } catch (error) {
      console.log(error)
    }
  }

  const handleNotesExternalSubmit = async (values, action) => {
    // console.log("handleNotesExternalSubmit", values)
    let index = timeline.findLastIndex(t=>t.status === "COMPLETED")
    let modifiedTimeline = [...timeline]
    let previousExternal = modifiedTimeline[index]?.external || {}
    let externalHistory = modifiedTimeline[index]?.external?.history || []
    let latestMessage = {
      ...previousExternal,
      history: [
        ...externalHistory,
        {
          message: values.notesExternal,
          createdAt: new Date().getTime(),
          agentId: user.uid
        }
      ]
    }
    modifiedTimeline[index].external = latestMessage
    const response = await updateTimeline(id, modifiedTimeline)
    if(response) {
      setTimeline(modifiedTimeline)
      action.resetForm()
    }
  }

  const handleNotesInternalSubmit = async (values, action) => {
    // console.log("handleNotesInternalSubmit", values)
    let index = timeline.findLastIndex(t=>t.status === "COMPLETED")
    let modifiedTimeline = [...timeline]
    let previousInternal = modifiedTimeline[index]?.internal || {}
    let internalHistory = modifiedTimeline[index]?.internal?.history || []
    let latestMessage = {
      ...previousInternal,
      history: [
        ...internalHistory,
        {
          message: values.notesInternal,
          createdAt: new Date().getTime(),
          agentId: user.uid
        }
      ]
    }
    modifiedTimeline[index].internal = latestMessage
    const response = await updateTimeline(id, modifiedTimeline)
    if(response) {
      setTimeline(modifiedTimeline)
      action.resetForm()
    }
  }

  const onOpenReportIdDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenReportIdDialog(true)
  }

  const renderMenuOptions = () => {
    let menuItems = ['Reviewing', 'Assigned', 'In Progress', 'Completed']
    return (
      menuItems.filter(i=>i!==columnName).map((item, index) => {
        const onMove = () => {
          let fromColumn = columnName.toLowerCase().replace(" ", "")
          let toColumn = item.toLowerCase().replace(" ", "")
          handleMoveCard(data, {fromPosition: menuItems.indexOf(columnName),fromColumnId: fromColumn}, {toPosition: menuItems.indexOf(item), toColumnId: toColumn})
          handleMoveMenuClose()
          handleCloseDetail()
        }
        return (
          <MenuItem key={index} onClick={onMove}>{item}</MenuItem>
        )
      })
    )
  }

  const handleMoveMenuOpen = (event) => {
    setMoveMenuEl(event.currentTarget);
  }
  const handleMoveMenuClose = () => {
    setMoveMenuEl(null)
  }

  return (
    <Dialog
      open={openDetail}
      onClose={handleCloseDetail}
      scroll={"paper"}
      fullWidth
      maxWidth="xl"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <MDBox  display="flex" alignItems="center" justifyContent="space-between" mx={2} ml={0}>
                <MDTypography variant="h4" color="white">{municipalName}</MDTypography>
              </MDBox>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox>
                <MDBox mb={4}>
                  <Carousel
                    autoPlay={false}
                    animation={'slide'}
                    indicators={false}
                  >
                    {images.map((item, i) => {
                      return (
                        <MDBox key={`img-${i}`} sx={{textAlign: 'center'}} onClick={() => window.open(item, "_blank")}>
                          <img src={item} width="auto" style={{cursor: 'pointer', maxHeight: '500px'}} />
                        </MDBox>
                      )
                    })}
                  </Carousel>
                </MDBox>

                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={0.5}
                  pb={3}
                  px={0}
                  lineHeight={1}
                >
                  <MDBox
                    display="flex"
                    alignItems="center"
                  >
                    <MDAvatar
                      key={`img-${reportorUserId}`}
                      src={userData.profilePicUrl || defaultAvatar}
                      alt={`${userData.firstName} ${userData.lastName}` || ""}
                      size="sm"
                      sx={{
                        border: ({ borders: { borderWidth }, palette: { white } }) =>
                          `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",
                        ml: 0,
                        mr: 1,

                        "&:hover, &:focus": {
                          zIndex: "10",
                        },
                      }}
                    />
                    <MDTypography variant="body2" fontWeight="regular" color="text">
                      {`${userData.firstName} ${userData.lastName}` || ""}
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="body2" fontWeight="regular" color="text">
                    {moment(Number(createdAt)).fromNow('y') + ' ago'}
                  </MDTypography>
                  <MDBox color="text" display="flex" alignItems="center">
                    {modifiedAt  &&
                      <Icon color="inherit" sx={{ m: 0.5 }}>
                        edit
                      </Icon>
                    }
                    <MDBadge size="xs" color={"dark"} badgeContent={"RDLG"} container />
                  </MDBox>
                </MDBox>
                
                <MDTypography variant="body2" color="text">
                  <>
                    <b>{issue}</b>{': '}
                    {description}
                  </>
                </MDTypography>
                <MDBox display="flex" alignItems="center" justifyContent="space-between">
                  <MDBox display="flex" alignItems="center">
                    <Icon>
                      {"visibility"}
                    </Icon>
                    <MDTypography variant="normal" color="text" ml={1}>
                      {viewedBy.length}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={1} >
                    <Switch checked={isVetted} onChange={handleIsVetted} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleIsVetted}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Vetted
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center">
                    <MDButton
                      id="menu-button"
                      aria-controls={!!moveMenuEl ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={!!moveMenuEl ? 'true' : undefined}
                      onClick={handleMoveMenuOpen}
                    >
                      Move Card
                    </MDButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={moveMenuEl}
                      open={!!moveMenuEl}
                      onClose={handleMoveMenuClose}
                      MenuListProps={{
                        'aria-labelledby': 'menu-button',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {renderMenuOptions()}
                    </Menu>
                  </MDBox>
                  <MDBox display="flex" alignItems="center" onClick={onOpenReportIdDialog}>
                    <MDButton variant="contained" color="info">Report ID</MDButton>
                  </MDBox>
                  <ReportIdDialog 
                    open={openReportIdDialog}
                    handleClose={() => setOpenReportIdDialog(false)}
                    reportId={reportUniqueId}
                  />
                </MDBox>
              </MDBox>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <MDBox display="flex" alignItems="center" justifyContent="space-between" mx={2} ml={0}>
                <MDTypography variant="h4" color="white">{"Notes (External)"}</MDTypography>
              </MDBox>
            </AccordionSummary>
            <AccordionDetails>
            <Formik
                initialValues={notesExternalInitialValues}
                validationSchema={notesExtValds}
                onSubmit={handleNotesExternalSubmit}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form id="notesExternalForm" autoComplete="off">
                    <MDBox mb={2}>
                      <FormField 
                        label="Type here..." 
                        name="notesExternal"
                        value={values.notesExternal}
                        error={errors.notesExternal && touched.notesExternal}
                        success={values.notesExternal.length > 0 && !errors.notesExternal}
                        multiline 
                        rows={5} 
                      />
                    </MDBox>
                    <MDBox display="flex" justifyContent="flex-end">
                      <MDButton variant="contained" type="submit" disabled={isSubmitting} color="info">Save</MDButton>
                    </MDBox>
                  </Form>
                )}
              </Formik>

              {/* Timeline of External notes */}
              <TimelineView 
                timeline={timeline} 
                type="external"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <MDBox display="flex" alignItems="center" justifyContent="space-between" mx={2} ml={0}>
                <MDTypography variant="h4" color="white">{"Notes (Internal)"}</MDTypography>
              </MDBox>
            </AccordionSummary>
            <AccordionDetails>
              <Formik
                initialValues={notesInternalInitialValues}
                validationSchema={notesIntValds}
                onSubmit={handleNotesInternalSubmit}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form id="notesInternalForm" autoComplete="off">
                    <MDBox mb={2}>
                      <FormField 
                        label="Type here..." 
                        name="notesInternal"
                        value={values.notesInternal}
                        error={errors.notesInternal && touched.notesInternal}
                        success={values.notesInternal.length > 0 && !errors.notesInternal}
                        multiline 
                        rows={5} 
                      />
                    </MDBox>
                    <MDBox display="flex" justifyContent="flex-end">
                      <MDButton variant="contained" type="submit" disabled={isSubmitting} color="info">Save</MDButton>
                    </MDBox>
                  </Form>
                )}
              </Formik>

              {/* Timeline of Internal notes */}
              <TimelineView 
                timeline={timeline} 
                type="internal"
              />
            </AccordionDetails>
          </Accordion>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default CardDetail;