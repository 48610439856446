import React from 'react'
import { LinearProgress } from '@mui/material';

import MDBox from "components/MDBox";

import PageLayout from "containers/LayoutContainers/PageLayout";

const LoadingScreen = () => (
  <PageLayout>
    <MDBox sx={{ width: '100%' }}>
      <LinearProgress />
    </MDBox>  
  </PageLayout>
)

export default LoadingScreen