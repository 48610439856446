import { useState, useEffect, useCallback, useMemo } from "react";

// @asseinfo/react-kanban components
import Board, { moveCard } from "@asseinfo/react-kanban";

// react-html-parser components
import ReactHtmlParser from "react-html-parser";

import { cloneDeep } from "lodash"

// @mui material components
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import LinearProgress from '@mui/material/LinearProgress';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import TableChartIcon from '@mui/icons-material/TableChart';
import CachedIcon from '@mui/icons-material/Cached';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { map } from "lodash"

// Otis Admin PRO React components
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "containers/Navbars/DashboardNavbar";
import DataTable from "containers/Tables/DataTable";
import Footer from "containers/Footer";

// Kanban application components
import Header from "layouts/dashboards/kanban/components/Header";
import Card from "layouts/dashboards/kanban/components/Card";
import CardDetail from "layouts/dashboards/kanban/components/CardDetail";
import ReportorAvatar from "./components/ReportorAvatar";

import { getUserInfo, getReports, updateTimeline, toggleVettedReport, deleteReportById } from "services/firebaseServices"

// React context
import { useMaterialUIController } from "context";
import { useKanban, addUser, setReports, setLatestReports } from "layouts/dashboards/kanban/context"

import "layouts/dashboards/kanban/style.css"
import { encodeImageUrl } from "utils/Utils";

const XLSX = require("xlsx")

const boards = {
  columns: [
    {
      id: "reviewing",
      title: "Reviewing",
      cards: []
    },
    {
      id: "assigned",
      title: "Assigned",
      cards: []
    },
    {
      id: "inprogress",
      title: "In Progress",
      cards: []
    },
    {
      id: "completed",
      title: "Completed",
      cards: []
    },
  ]
}

const actionsColumn = { 
  Header: "Actions", 
  accessor: "actions", 
  width: "10%", 
  sorting: false,
  Cell: ({value}) => <MDBox>
    <Tooltip key={"Actions"} title={"Actions"} placement="bottom">
      {value.menu}
    </Tooltip>
  </MDBox>
}

const dataTableData = {
  columns: [
    { Header: "#", accessor: "reportUniqueId"},
    { 
      Header: "Issue", 
      accessor: "issueType", 
      Cell: props => <MDBox display={'flex'} justifyContent={"center"} alignItems="center">
        <MDBox component="img" src={props.value.reportImage} height={"50px"} width="50px" sx={{ borderRadius: '4px'}} />
        <MDTypography sx={{marginLeft: '10px', maxWidth: "150px"}} variant="body" fontWeight="regular">{`${props.value.name}`}</MDTypography>
      </MDBox> 
    },
    { Header: "Municipal", accessor: "municipalName", width: "15%" },
    { 
      Header: "Reportor Name", 
      accessor: "reportorUserId", 
      Cell: props => <ReportorAvatar uid={props.value} /> 
    },
    { Header: "Status", accessor: "reportStatus", Cell: props => <div>{props.value}</div>},
    { Header: "Vetted", accessor: "isVetted", Cell: props => <div>{props.value? "TRUE" : "FALSE"}</div>},
    {...actionsColumn}
  ],
  rows: []
}

const initialSBConfig = {
  color: "info",
  icon: "notifications",
  content: "",
  open: false,
}

let globalReportsState = []

function Reports() {
  const [controller] = useMaterialUIController();
  const { darkMode, municipals, selectedMunicipal, selectedReportType, reportFlagFilter, reportTagFilter, reportDateFilter, reportTypesIssues, user, allReportTags, tenant, reportIdFilter } = controller;
  const [stateKanban, dispatchKanban] = useKanban()
  const { reports, users } = stateKanban;
  globalReportsState = reports

  const [loading, setLoading] = useState(false)
  // const [reports, setReports] = useState([]);
  const [board, setBoard] = useState(boards)
  // const [tableData, setTableData] = useState(user.role === "VIEWER" ? dataTableData : {...dataTableData, columns: [...dataTableData.columns, actionsColumn]})
  const [tableData, setTableData] = useState(dataTableData)
  const [openDetailDialog, setOpenDetailDialog] = useState(false)
  const [reportDetailData, setReportDetailData] = useState(null)
  const [defaultExpandPanel, setDefaultExpandPanel] = useState("panel1")
  const [tabValue, setTabValue] = useState(0);
  const [snackbarConfig, setSnackbarConfig] = useState({...initialSBConfig})
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [preparingReports, setPreparingReports] = useState(false)

  useEffect(() => {
    getBoardData(reports)
  }, [selectedMunicipal, selectedReportType, reportIdFilter, reportFlagFilter, reportTagFilter, reportDateFilter])

  useEffect(() => {
    // console.log("globalReportsState A", globalReportsState)
    globalReportsState = reports
    // console.log("globalReportsState B", globalReportsState)
  }, [reports])

  const getBoardData = async () => {
    console.log("tenant", tenant)
    let reportType = reportTypesIssues.find(r=>r.id === selectedReportType)?.type || selectedReportType
    let tenantFilter = tenant === null ? null : allReportTags.find((t) => t.key === tenant.name)?.id ?? null
    getReports(selectedMunicipal, reportType, tenantFilter, reportIdFilter, reportFlagFilter, reportTagFilter, reportDateFilter, setReportsStore)
  }

  const setReportsStore = (data, reset=false) => {
    // if no report data found
    if(data.length === 0) {
      setReports(dispatchKanban, data)
      return
    }
    if(reset) {
      setReports(dispatchKanban, data)
      return
    }
    // console.log("getBoardData", data.length)
    // console.log("getBoardData reports", globalReportsState[0].municipal, selectedMunicipal)
    if(globalReportsState.length > 0 && globalReportsState[0].municipal === selectedMunicipal) {
      // console.log("AA")
      const existingReportsIds = map(globalReportsState, 'id')
      const newReports = data.filter(nr => !existingReportsIds.includes(nr.id))
      const updatedReports = data.filter(nr => existingReportsIds.includes(nr.id))
      const lastPost = updatedReports[0] || {}
      let latestReport = []
      // console.log("BB", existingReportsIds)
      // console.log("CC", newReports)
      // console.log("DD", updatedReports)
      newReports.forEach(nr => {
        // console.log("nr.createdAt > (lastPost?.createdAt || 0)", nr.createdAt, lastPost?.createdAt)
        if(nr.createdAt > (lastPost?.createdAt || 0)) {
          latestReport.push(nr)
        } else {
          updatedReports.push(nr)
        }
      })
      // console.log("EE", latestReport)
      if(updatedReports.length > 0 ) {
        setReports(dispatchKanban, [...updatedReports])
      }
      else if(latestReport.length > 0 ) {
        setLatestReports(dispatchKanban, [...latestReport])
      }

    } else {
      // console.log("ZZ")
      setReports(dispatchKanban, data)
    }
  }

  useEffect(() => {
    let data = cloneDeep(boards.columns)
    reports.map((report) => {
      let idx = report.timeline.findLastIndex(t=>t.status === "COMPLETED")
      let card = {
        id: report.id,
        template: (
          <Card
            data={report}
            columnIndex={idx}
            handleClick={handleOpenDialog}
            handleMoveCard={handleCardDragEnd}
          />
        )
      }
      data[idx].cards.push(card)
    })
    setBoard({columns: data})
  }, [reports])

  useEffect(() => {
    if(tabValue === 1) {
      // set tabular view data
      const reportsTableData = reports.map((rt) => (
        {
          ...rt,
          issueType: {
            reportImage: rt.images[0],
            name: rt.issue
          },
          municipalName: rt.municipal? municipals.find(m => m.id === rt.municipal)?.long_name : "", 
          reportStatus: rt.timeline.findLast(t=>t.status === "COMPLETED").title,
          actions: {
            menu: <MoreMenu 
                    reportData={rt} 
                    handleMenuActions={handleMenuActions}
                    userRole={user.role}
                  />
          }
      }))
      setTableData({
        ...tableData,
        rows: [...reportsTableData]
      })
    }
  }, [reports, tabValue])

  const handleMenuActions = (actionType, data) => {
    switch (actionType) {

      case "handleView":
        handleOpenDialog(data)
        break;

      case "handleVetted":
        toggleReportVetted(data)
        break;

      case "handleDelete":
        if(confirm("Do you want to delete report?")){
          deleteReport(data)
        }
        break;

      default:
        break;
    }
  }

  const toggleSnackbar = () => setShowSnackbar(!showSnackbar)

  const toggleReportVetted = (rt) => {
    /* 
      * check validation before vetting report
      * atleast one tag associated with the report to make it vetted
    */
    if(!!rt?.reportTags === false || rt?.reportTags.length === 0) {
      setShowSnackbar(true)
      // alert("Please add report tag to report first.")
      return false
    }
    setLoading(true)
    try {
      toggleVettedReport(rt.id, !rt.isVetted)
      .then(() => {
        setReports(dispatchKanban, reports.map(rpt => {
          if(rpt.id === rt.id) {
            return {
              ...rpt,
              isVetted: !rpt.isVetted
            }
          } else {
            return {
              ...rpt
            }
          }
        }))

        setSnackbarConfig({
          ...snackbarConfig,
          color: "success",
          title: "Local TT",
          content: `Report ${rt.reportUniqueId} has been ${!rt.isVetted? 'vetted' : 'unvetted'}`,
          open: true,
          close: toggleSnackbarConfig
        })
        setLoading(false)
      }, error => {
        setSnackbarConfig({
          ...snackbarConfig,
          color: "error",
          title: "Local TT",
          content: error.message,
          open: true,
          close: toggleSnackbarConfig
        })
        setLoading(false)
      })
      
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const deleteReport = (rt) => {
    deleteReportById(rt)
    .then(() => {
      setReports(dispatchKanban, reports.filter(rpt => {
        if(rpt.id !== rt.id) {
          return {
            ...rpt
          }
        }
      }))

      setSnackbarConfig({
        ...snackbarConfig,
        color: "success",
        title: "Local TT",
        content: `Report has been deleted`,
        open: true,
        close: toggleSnackbarConfig
      })
      setLoading(false)
    }, error => {
      setSnackbarConfig({
        ...snackbarConfig,
        color: "error",
        title: "Local TT",
        content: error.message,
        open: true,
        close: toggleSnackbarConfig
      })
      setLoading(false)
    })
  }

  const toggleSnackbarConfig = () => setSnackbarConfig({...initialSBConfig})

  const handleOpenDialog = (reportData) => {
    setOpenDetailDialog(true)
    setReportDetailData(reportData)
  }

  const handleCloseDialog = () => {
    setOpenDetailDialog(false)
    setReportDetailData(null)
    setDefaultExpandPanel("panel1")
  }

  const getColumnCardsCount = (colIndex, board) => {
    let cnt = board.columns.find(c => c.id === colIndex)?.cards.length || 0
    return cnt
  }

  const handleCardDragEnd = async (card, source, destination) => {
    if( user.role === "VIEWER" ) return false
    // console.log("handleCardDragEnd", card, source, destination)
    const newBoard = moveCard(board, source, destination)
    setBoard(newBoard)

    // update firestore with this change in timeline
    let reportId = card.id
    let { fromColumnId } = source
    let { toColumnId } = destination
    let sourceListIndex = boards.columns.findIndex(l=>l.id===fromColumnId)
    let desListIndex = boards.columns.findIndex(l=>l.id===toColumnId)
    let thisReport = reports.find(rt => rt.id === reportId)
    let modifiedTimeline = thisReport.timeline

    // decide move left to right OR right to left
    if(sourceListIndex < desListIndex) { // move left to right
      modifiedTimeline.map((t, i) => {
        if(i <= desListIndex) {
          t.status = "COMPLETED"
        }
      })
      // console.log("modifiedTimeline", modifiedTimeline)
      const response = await updateTimeline(reportId, modifiedTimeline)
      if(response) {
        setReports(dispatchKanban, reports.map(rt => {
          if(rt.id === reportId) {
            return {
              ...rt,
              timeline: modifiedTimeline
            }
          } else {
            return {
              ...rt
            }
          }
        }))
      }
      handleOpenDialog(thisReport)
      setDefaultExpandPanel("panel2")
    } else { // move right to left
      modifiedTimeline.map((t, i) => {
        if(i > desListIndex && i <= sourceListIndex) {
          t.status = "IN_COMPLETED"
        }
      })
      // console.log("modifiedTimeline", modifiedTimeline)
      const response = await updateTimeline(reportId, modifiedTimeline)
      if(response) {
        setReports(dispatchKanban, reports.map(rt => {
          if(rt.id === reportId) {
            return {
              ...rt,
              timeline: modifiedTimeline
            }
          } else {
            return {
              ...rt
            }
          }
        }))
      }
    }
  }

  const updateReport = (id, modifiedReport) => {
    setReports(dispatchKanban, reports.map(rt => {
      return rt.id === id? modifiedReport : rt
    }))
  }

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const prepareReportData = () => {
    setPreparingReports(true)
    let allUsersPromise = []
    reports.forEach(({reportorUserId}) => {
      const user = new Promise(async (resolve, reject) => {
        if(!!users[reportorUserId] === false) {
          const userSnap = await getUserInfo(reportorUserId)
          if(userSnap.exists) {
            resolve(userSnap.data())
            addUser(dispatchKanban, userSnap.data())
          } else {
            resolve()
          }
        } else {
          console.log("Save request!")
          resolve(users[reportorUserId])
        }  
      })
      allUsersPromise.push(user)
    })

    return Promise.all(allUsersPromise).then((values) => {
      setPreparingReports(false)
      return values
    })

  }

  const handleExport = async () => {
    try {
      const allReportUsers = await prepareReportData()
      const municipalName = municipals.find(m => m.id === selectedMunicipal)?.name ?? "sheet1"
      const reportsData = reports.map(r => {
        const rptUsr = allReportUsers.find(u => u.uid === r.reportorUserId)
        const reportor = `${rptUsr?.firstName ?? ''}`
        const reportorEmail = rptUsr?.email
        const reportorPhoneNumber = rptUsr?.phoneNumber
        const reportStatus = r.timeline.findLast(rt => rt.status === "COMPLETED")?.title ?? ''
        let tags = r?.reportTags? r.reportTags : []
        tags = tags.map(tag => allReportTags.find(t => t.id === tag)?.name ?? "")
        tags = tags.join(', ')
        const reportImgsArr = r?.images ?? []
        const reportImages = {}
        reportImgsArr.forEach((img, i) => {
          let link = encodeImageUrl(img)
          reportImages[`report image ${i+1}`] = {v: img, l: { Target: link}}
        })
        
        return ({
          'Report Id': r.reportUniqueId,
          'Report Category': r.type,
          'Report Type': r.issue,
          'Description': r.description,
          'Municipal': municipalName,
          'Location': { v: `https://www.google.com/maps/search/?api=1&query=${r.geo._latitude},${r.geo._longitude}`, l: { Target: `https://www.google.com/maps/search/?api=1&query=${r.geo._latitude},${r.geo._longitude}` } },
          'Report Tags': tags,
          'Reportor': reportor,
          'Profile image': { v: rptUsr?.profilePicUrl, l: { Target: rptUsr?.profilePicUrl} },
          'Email/Phone Number': `${reportorEmail? reportorEmail: ''}${(!!reportorEmail && !!reportorPhoneNumber)? ' | ' : ''}${reportorPhoneNumber}`,
          'Status': reportStatus,
          'Date': new Date(r.createdAt).toDateString(),
          ...reportImages
        })
      })
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(reportsData);
      worksheet["!cols"] = [ { wch: 12 }, { wch: 20 }, { wch: 50 }, { wch: 100 }, { wch: 30}, { wch: 100 }, { wch: 30 }, { wch: 15 }, { wch: 100 }, { wch: 35 }, { wch: 15 }, { wch: 15 }, { wch: 100 }, { wch: 100 }, { wch: 100 }, { wch: 100 }, { wch: 100 }, { wch: 100 } ];

      XLSX.utils.book_append_sheet(workbook, worksheet, municipalName);

      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "reportsData.xlsx";
      a.click();

      URL.revokeObjectURL(url);
      
    } catch (error) {
      console.log("handleExport error", error)
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox display="flex" justifyContent="flex-end" m={2}>
          <Header />
        </MDBox>

        <MDBox display="flex" m={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6} lg={4} sx={{ mr: "auto" }}>
              <AppBar position="static">
                <Tabs orientation={"horizontal"} value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label="Kanban"
                    icon={
                      <ViewKanbanIcon fontSize="small" sx={{ mt: -0.25 }}/>
                    }
                  />
                  <Tab
                    label="Tabular"
                    icon={
                      <TableChartIcon fontSize="small" sx={{ mt: -0.25 }}/>
                    }
                  />
                </Tabs>
              </AppBar>

              <MDBox sx={{display: 'flex', flexDirection: 'row', gap: 4, marginTop: '10px', cursor: 'pointer'}}>
                <CachedIcon name="CachedIcon" exact sx={{ mt: 0.25 }} />
                {reports?.length > 0 &&
                  <MDBox variant="button" sx={{display: 'flex', flexDirection: 'row', gap: 1}} onClick={handleExport}>
                    <MDTypography variant="h6">Export Reports</MDTypography>
                    <FileDownloadIcon exact sx={{ mt: 0.25 }} />
                  </MDBox>
                }
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        
        {tabValue === 0 &&

          <MDBox
            position="relative"
            my={4}
            sx={({
              palette: { light, background },
              functions: { pxToRem },
              borders: { borderRadius },
            }) => ({
              "& .react-kanban-column": {
                backgroundColor: darkMode ? background.card : light.main,
                width: pxToRem(450),
                margin: `0 ${pxToRem(10)}`,
                padding: pxToRem(20),
                borderRadius: borderRadius.lg,
              },
            })}
          >
            <Board
              allowAddCard
              allowAddColumn
              renderColumnHeader={({ id, title }) => (
                <>
                  <MDBox display="flex" justifyContent="flex-start" alignItems="center" mb={3}>
                    <MDTypography variant="h6">{title}</MDTypography>
                    <MDBadge badgeContent={getColumnCardsCount(id, board)} container sx={{ml:2}} />
                  </MDBox>
                </>
              )}
              renderCard={({ id, template }, { dragging }) => (
                <MDBox
                  key={id}
                  dragging={dragging.toString() || undefined}
                  display="block"
                  width="calc(450px - 40px)"
                  bgColor={darkMode ? "transparent" : "white"}
                  color="text"
                  borderRadius="xl"
                  mt={2.5}
                  py={1.875}
                  px={1.875}
                  lineHeight={1.5}
                  sx={{
                    border: ({ borders: { borderWidth }, palette: { white } }) =>
                      darkMode ? `${borderWidth[1]} solid ${white.main}` : 0,
                    fontSize: ({ typography: { size } }) => size.md,
                  }}
                >
                  {typeof template === "string" ? ReactHtmlParser(template) : template}
                </MDBox>
              )}
              onCardNew={() => null}
              onCardDragEnd={handleCardDragEnd}
            >
              {board}
            </Board>
          </MDBox>
        }
        {tabValue === 1 &&
          <MDBox
            position="relative"
            my={4}
            sx={({
              palette: { light, background },
              functions: { pxToRem },
              borders: { borderRadius },
            }) => ({
              "& .react-kanban-column": {
                backgroundColor: darkMode ? background.card : light.main,
                width: pxToRem(450),
                margin: `0 ${pxToRem(10)}`,
                padding: pxToRem(20),
                borderRadius: borderRadius.lg,
              },
            })}
          >
            {(loading || preparingReports) &&
              <MDBox sx={{
                overflow: 'hidden'
              }}>
                <LinearProgress />
              </MDBox>
            }
            <DataTable 
              table={tableData}
              // formatRowProps={(state) => formatTrProps(state)}
            />
          </MDBox>
        }
        {openDetailDialog &&
          <CardDetail 
            data={reportDetailData} 
            openDetail={openDetailDialog} 
            handleCloseDetail={handleCloseDialog} 
            handleMoveCard={handleCardDragEnd}
            updateReport={updateReport}
            defaultExpandPanel={defaultExpandPanel} 
            />
        }
      </MDBox>

      <MDSnackbar
        {...snackbarConfig}
      />

      <MDSnackbar
        color="error"
        icon="close"
        title="Validation Error"
        content="Please add tag(s) to report first."
        open={showSnackbar}
        close={toggleSnackbar}
      />

      <Footer />
    </DashboardLayout>
  );
}

export default Reports;


const MoreMenu = ({ reportData, handleMenuActions, userRole }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleView = () => {
    handleMenuActions("handleView", reportData)
    handleClose()
  }

  const handleVetted = () => {
    handleMenuActions("handleVetted", reportData)
    handleClose()
  }

  const handleDelete = () => {
    handleMenuActions("handleDelete", reportData)
    handleClose()
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleView}>{"View"}</MenuItem>
        {userRole !== "VIEWER" &&
          <MenuItem onClick={handleVetted}>{reportData.isVetted ? "Unvetted" : "Vetted"}</MenuItem>
        }
        {userRole !== "VIEWER" &&
          <MenuItem onClick={handleDelete}>{"Delete"}</MenuItem>
        }
      </Menu>
    </>
  )
}