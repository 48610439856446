import { useState, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

// @mui material components
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Dialog from '@mui/material/Dialog';
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
import DialogContentText from '@mui/material/DialogContentText';

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";

// Otis Admin PRO React example components
import DashboardLayout from "containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "containers/Navbars/DashboardNavbar";
import Footer from "containers/Footer";
import DataTable from "containers/Tables/DataTable";
import MDAvatar from "components/MDAvatar";
import IdentityDocuments from "./components/IdentityDocuments";
import CardDetail from "./components/CardDetail";
// import ReportorAvatar from "../kanban/components/ReportorAvatar";

import { updateUserProfile, postNotification, getProfileUpdateRequests, getMyReports, toggleVettedReport, updateTimeline } from "services/firebaseServices"

import defaultAvatar from "assets/images/defaultAvatar.png"

// React context
import { useMaterialUIController } from "context";

const values={ notes: '' }
const validations = Yup.object().shape({
  notes: Yup.string().required("This field is required").max(256)
})

const initialSBConfig = {
  color: "info",
  icon: "notifications",
  content: "",
  open: false,
}

const dataTableData = {
  columns: [
    { Header: "#", accessor: "reportUniqueId"},
    { 
      Header: "Issue", 
      accessor: "issueType", 
      Cell: props => <MDBox display={'flex'} justifyContent={"center"} alignItems="center">
        <MDBox component="img" src={props.value.reportImage} height={"50px"} width="50px" sx={{ borderRadius: '4px'}} />
        <MDTypography sx={{marginLeft: '10px', maxWidth: "150px"}} variant="body" fontWeight="regular">{`${props.value.name}`}</MDTypography>
      </MDBox> 
    },
    { Header: "Municipal", accessor: "municipalName", width: "15%" },
    { 
      Header: "Reportor Name", 
      accessor: "reportorUserId", 
      Cell: props => <MDBox display={'flex'} justifyContent={"center"} alignItems="center">
                      <MDAvatar src={props.value.profilePicUrl || defaultAvatar} alt="profile-image" size="md" shadow="sm" />
                      <MDTypography sx={{marginLeft: '10px'}} variant="body" fontWeight="regular">{`${props.value?.firstName || ''} ${props.value?.lastName || ''}`}</MDTypography>
                    </MDBox>
    },
    { Header: "Status", accessor: "reportStatus", Cell: props => <div>{props.value}</div>},
    { Header: "Vetted", accessor: "isVetted", Cell: props => <div>{props.value? "TRUE" : "FALSE"}</div>},
    { 
      Header: "Actions", 
      accessor: "actions", 
      width: "10%", 
      sorting: false,
      Cell: ({value}) => <MDBox>
        <Tooltip key={"Actions"} title={"Actions"} placement="bottom">
          {value.menu}
        </Tooltip>
      </MDBox>
    },
  ],
  rows: []
}

function UserDetail({absolute, light, isMini}) {
  const location = useLocation();
  const params = useParams();
  const [controller] = useMaterialUIController();
  const { darkMode, municipals } = controller;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false)
  const [openCancelIdentityDialog, setOpenCancelIdentityDialog] = useState(false)
  const [openConfirmIdentityDialog, setOpenConfirmIdentityDialog] = useState(false)
  const [user, setUser] = useState({...location.state});
  const [reportsTableData, setReportsTableData] = useState(dataTableData)
  const [snackbarConfig, setSnackbarConfig] = useState({...initialSBConfig})
  const [openDetailDialog, setOpenDetailDialog] = useState(false)
  const [reportDetailData, setReportDetailData] = useState(null)
  const [defaultExpandPanel, setDefaultExpandPanel] = useState("panel1")


  useEffect(() => {
    (async () => {
      const resp = await getProfileUpdateRequests(user.uid)
      // console.log("getProfileUpdateRequests", resp)

      try {
        const userReportsData = await getMyReports(user.uid)
        const tableData = userReportsData.map((rt) => (
          {
            ...rt,
            issueType: {
              reportImage: rt.images[0],
              name: rt.issue
            },
            municipalName: rt.municipal? municipals.find(m => m.id === rt.municipal)?.long_name : "", 
            reportorUserId: {...user},
            reportStatus: rt.timeline.findLast(t=>t.status === "COMPLETED").title,
            actions: {
              menu: <MoreMenu 
                      reportData={rt} 
                      handleMenuActions={handleMenuActions}
                    />
            }
        }))
        setReportsTableData({
          ...reportsTableData,
          rows: [...tableData]
        })
      } catch (error) {
        console.log("getMyReports", error)
      }

    })()
  }, [])

  useEffect(() => {
    console.log("reportsTableData change: ", reportsTableData)
  }, [reportsTableData])

  const handleMenuActions = (actionType, data) => {
    switch (actionType) {

      case "handleView":
        handleOpenDialog(data)
        break;

      case "handleVetted":
        toggleReportVetted(data)
        break;

      default:
        break;
    }
  }

  const handleOpenDialog = (reportData) => {
    // console.log("handleOpenDialog", reportData)
    setOpenDetailDialog(true)
    setReportDetailData(reportData)
  }

  const handleCloseDialog = () => {
    setOpenDetailDialog(false)
    setReportDetailData(null)
    setDefaultExpandPanel("panel1")
  }

  const toggleReportVetted = async (rt) => {
    setLoading(true)
    try {
      await toggleVettedReport(rt.id, !rt.isVetted)

      setReportsTableData(reportsTableData => {
        const index = reportsTableData.rows.findIndex(rpt => rpt.id === rt.id)
        const modifiedReport = {
          ...reportsTableData.rows[index],
            isVetted: !reportsTableData.rows[index].isVetted,
        }
        return ({
        ...reportsTableData,
        rows: [
          ...reportsTableData.rows.splice(0, index),
          {
            ...modifiedReport,
            actions: {
              menu: <MoreMenu 
                      reportData={modifiedReport} 
                      handleMenuActions={handleMenuActions}
                    />
            }
          },
          ...reportsTableData.rows.splice(index+1),
        ]
      })})

      setSnackbarConfig({
        ...snackbarConfig,
        color: "success",
        title: "Local TT",
        content: `Report ${rt.reportUniqueId} has been ${!rt.isVetted? 'vetted' : 'unvetted'}`,
        open: true,
        close: toggleSnackbarConfig
      })
      setLoading(false)
      
    } catch (error) {
      console.log(error)
      setSnackbarConfig({
        ...snackbarConfig,
        color: "error",
        title: "Local TT",
        content: error.message,
        open: true,
        close: toggleSnackbarConfig
      })
      setLoading(false)
    }
  }

  const toggleSnackbarConfig = () => setSnackbarConfig({...initialSBConfig})

  const toggleSnackbar = () => setShowSnackbar(!showSnackbar)

  const handleMoveReport = async (rt, source, destination) => {
    let modifiedTimeline = rt.timeline
    let reportId = rt.id

    // decide move left to right OR right to left
    if(source.fromPosition < destination.toPosition) { // move left to right
      modifiedTimeline.map((t, i) => {
        if(i <= destination.toPosition) {
          t.status = "COMPLETED"
        }
      })
    } else { // move right to left
      modifiedTimeline.map((t, i) => {
        if(i > destination.toPosition && i <= source.fromPosition) {
          t.status = "IN_COMPLETED"
        }
      })
    }

    const response = await updateTimeline(reportId, modifiedTimeline)
    if(response) {
      updateReport(
        reportId, 
        {
          timeline: modifiedTimeline,
          reportStatus: modifiedTimeline.findLast(t=>t.status === "COMPLETED").title,
        })
    }
  }

  const updateReport = (id, modifiedReport) => {
    setReportsTableData(reportsTableData => {
      const index = reportsTableData.rows.findIndex(rpt => rpt.id === id)
      return ({
      ...reportsTableData,
      rows: [
        ...reportsTableData.rows.splice(0, index),
        {
          ...reportsTableData.rows[index],
          ...modifiedReport,
          actions: {
            menu: <MoreMenu 
                    reportData={modifiedReport} 
                    handleMenuActions={handleMenuActions}
                  />
          }
        },
        ...reportsTableData.rows.splice(index+1),
      ]
    })})
  }

  // Styles for the icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      return colorValue;
    },
  });

  const approveIdentityDocs = () => {
    setOpenConfirmIdentityDialog(true)
  }
  const cancelIdentityDocs = () => {
    setOpenCancelIdentityDialog(true)
  }

  const handleSubmitCancelIdentity = () => {
    setOpenCancelIdentityDialog(false)
  }

  const handleCloseCancelIdentityDialog = () => {
    setOpenCancelIdentityDialog(false)
  }

  const handleCloseConfirmIdentityDialog = () => {
    setOpenConfirmIdentityDialog(false)
  }

  const handleConfirmIdentityDialog = async () => {
    setLoading(true)
    try {
      await updateUserProfile(user.id, { verifiedStatus: "COMPLETED" }) 
      setOpenConfirmIdentityDialog(false)
      setUser({
        ...user,
        verifiedStatus: "COMPLETED"
      })

      // push notification
      const data = {
        type: "LocalTT",
        topic: user.id,
        title: "LocalTT",
        message: `Congratulations ${user.firstName}, your documents were successfully vetted and you've now become a verified source on our Local TT platform.`,
      }
      await postNotification({
        ...data,
        createdAt: new Date().getTime()
      })

    } catch (error) {
      console.log("identity confirmation error", error)
      alert(error.message)
    }
    setLoading(false)
  }

  const handleDenyNotesSubmit = async (values, actions) => {
    // console.log("handleDenyNotesSubmit", values)
    let data = {
      type: "LocalTT",
      topic: user.id,
      title: "LocalTT",
      message: values.notes,
    }
    setLoading(true)
    try {
      await updateUserProfile(user.id, { verifiedStatus: "INCOMPLETED" }) 
      setUser({
        ...user,
        verifiedStatus: "INCOMPLETED"
      })
    } catch (error) {
      console.log("identity deny request error", error)
      alert(error.message)
      return false
    }

    const response = await postNotification({
      ...data,
      createdAt: new Date().getTime()
    })
    if(response) {
      setShowSnackbar(true)
      setOpenCancelIdentityDialog(false)
      actions.resetForm()
    } else {
      actions.setSubmitting(false);
    }
    setLoading(false)
  }

  const renderAccountId = () => {
    return !!user.providerId === false? user.email : user.phoneNumber
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {loading &&
          <MDBox sx={{
            overflow: 'hidden'
          }}>
            <LinearProgress />
          </MDBox>
        }
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Card
              sx={{
                position: "relative",
                py: 2,
                px: 2,
              }}
            >
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <MDAvatar src={user.profilePicUrl || defaultAvatar} alt="profile-image" size="xl" shadow="sm" />
                </Grid>
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {`${user.firstName} ${user.lastName}`}
                    </MDTypography>
                    <MDTypography variant="h6" color="text" fontWeight="regular">
                      {`Email: ${user.email || "N/A"}`}
                    </MDTypography>
                    <MDTypography variant="h6" color="text" fontWeight="regular">
                      {`Phone number: ${user.phoneNumber || "N/A"}`}
                    </MDTypography>
                    <MDTypography variant="h6" color="text" fontWeight="regular">
                      {`DOB: ${user.dob? new Date(user.dob).toDateString() : "N/A"}`}
                    </MDTypography>
                    <MDTypography variant="h6" color="text" fontWeight="regular">
                      {`Gender: ${user.gender || "N/A"}`}
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {municipals.find(m => m.id === user.location)?.long_name || ""}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
                  <MDBox>
                    <MDTypography variant="body2" fontWeight="medium">{`Total Reports: ${user.totalReports}`}</MDTypography>
                    <MDTypography variant="body2" fontWeight="medium">{`Bookmark Reports: ${user.totalBookmarked}`}</MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox p={3} display={"flex"}  justifyContent="space-between" alignItems="center">
                <MDTypography variant="h5">Identity Documents</MDTypography>
                <MDBox>
                    
                {user.verifiedStatus === "PENDING" ?
                  <>
                    <Tooltip key={"Cancel"} title={"Cancel"} placement="bottom">
                      <IconButton sx={{cursor: 'pointer', margin: '0 10px'}} onClick={cancelIdentityDocs} size="small" disableRipple>
                        <Icon fontSize="medium">
                          {"thumb_down"}
                        </Icon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip key={"Approve"} title={"Approve"} placement="bottom">
                      <IconButton sx={{cursor: 'pointer', margin: '0 10px', marginTop: '-10px'}} onClick={approveIdentityDocs} size="small" disableRipple>
                        <Icon fontSize="medium" sx={iconsStyle}>
                          {"thumb_up_alt"}
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </>
                  : user.verifiedStatus === "COMPLETED" ? 
                    <Tooltip key={"Cancel"} title={"Verified"} placement="bottom">
                      <IconButton sx={{cursor: 'pointer', margin: '0 10px'}} size="small" disableRipple>
                        <Icon fontSize="medium" color="success">
                          {"verified"}
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  : null
                }
              </MDBox>
            </MDBox>
              {/* Cancel Identity documents dialog */}
              <Dialog
                open={openCancelIdentityDialog}
                onClose={handleCloseCancelIdentityDialog}
                maxWidth="sm"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" align="center">
                  {"Identity documents request denied"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Formik
                      initialValues={values}
                      validationSchema={validations}
                      onSubmit={handleDenyNotesSubmit}
                    >
                      {({ values, errors, touched, isSubmitting }) => (
                        <Form id="cancelRequestForm" autoComplete="off">
                          <MDBox mb={2}>
                            <FormField 
                              label="Type here reason for denying documents..." 
                              name="notes"
                              value={values.notes}
                              error={errors.notes && touched.notes}
                              success={values.notes.length > 0 && !errors.notes}
                              multiline 
                              rows={5} 
                            />
                          </MDBox>
                          <MDBox display="flex" justifyContent="flex-end">
                            <MDButton onClick={handleCloseCancelIdentityDialog} autoFocus color={"light"}>
                              Cancel
                            </MDButton>
                            <MDButton sx={{marginLeft: '20px'}} variant="contained" type="submit" disabled={isSubmitting} color="info">Send</MDButton>
                          </MDBox>
                        </Form>
                      )}
                    </Formik>
                  </DialogContentText>
                </DialogContent>
              </Dialog>

              {/* Confirm Identity documents dialog */}
              <Dialog
                open={openConfirmIdentityDialog}
                onClose={handleCloseConfirmIdentityDialog}
                maxWidth="sm"
                fullWidth
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
              >
                <DialogTitle id="confirm-dialog-title" align="center">
                  {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="confirm-dialog-description">
                    {'You are going to confirm user`s identity documents'}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <MDButton onClick={handleCloseConfirmIdentityDialog} disabled={loading} autoFocus color={"light"}>Cancel</MDButton>
                  <MDButton sx={{marginLeft: '20px'}} onClick={handleConfirmIdentityDialog} disabled={loading} autoFocus color={"info"}>Confirm</MDButton>
                </DialogActions>
              </Dialog>

              {user.verifiedStatus === "PENDING" || user.verifiedStatus === "COMPLETED" ?
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <IdentityDocuments 
                      user={user}
                    />
                  </Grid>
                </Grid>
                :
                <MDBox px={3} pb={3} display={"flex"}  justifyContent="space-between" alignItems="center">
                  <MDTypography variant="body">No Document Provided</MDTypography>
                </MDBox>
              }
            </Card>
          </Grid>

          {user.totalReports ?
            <Grid item xs={12}>
              <Card>
                <MDBox p={3} display={"flex"}  justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h5">Reports</MDTypography>
                </MDBox>
                <MDBox pt={4} my={4}>
                  <DataTable 
                    table={reportsTableData}
                  />
                </MDBox>
              </Card>
           
              {openDetailDialog &&
                <CardDetail 
                  data={reportDetailData} 
                  userData={user}
                  openDetail={openDetailDialog} 
                  handleCloseDetail={handleCloseDialog} 
                  handleMoveCard={handleMoveReport}
                  updateReport={updateReport}
                  defaultExpandPanel={defaultExpandPanel} 
                  />
              }
            </Grid>
          : null
          }

        </Grid>
      </MDBox>

      <MDSnackbar
        color="info"
        icon="notifications"
        title="Identity request denied"
        content="Identity request denied and relevant notification message sent"
        open={showSnackbar}
        close={toggleSnackbar}
      />

      <MDSnackbar
        {...snackbarConfig}
      />

      <Footer />
    </DashboardLayout>
  )
}

export default UserDetail

const MoreMenu = ({ reportData, handleMenuActions }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleView = () => {
    handleMenuActions("handleView", reportData)
    handleClose()
  }

  const handleVetted = () => {
    handleMenuActions("handleVetted", reportData)
    handleClose()
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleView}>{"View"}</MenuItem>
        <MenuItem onClick={handleVetted}>{reportData.isVetted ? "Unvetted" : "Vetted"}</MenuItem>
      </Menu>
    </>
  )
}