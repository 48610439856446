import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon"

import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

import Sidenav from "containers/Sidenav";

// Otis Admin PRO React themes
import theme from "assets/theme";

// Otis Admin PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Otis Admin PRO React routes
import routes from "routes";
import PrivateOutlet from "containers/PrivateOutlet"

// Otis Admin PRO React components
import MDSnackbar from "components/MDSnackbar";
import MDAvatar from "components/MDAvatar";

import SignIn from "layouts/authentication/sign-in";

import { getLocations, getReportTypesIssues, getReportTags, getAgentUserInfo, checkSubdomainExist } from "services/firebaseServices"

import { isViewerToken, isAgentToken, isAdminToken, getSubdomain, isSubdomainUser } from "utils/Utils"

// Otis Admin PRO React contexts
import { useMaterialUIController, setMiniSidenav, sessionSuccess, sessionOut, loadSession, setMunicipals, setSelectedMunicipal, setReportTypesIssues, setReportTags, setSelectedReportType, setTenant } from "context";

// Images
import brandWhite from "assets/images/localTTAdminLogo.png";
import brandDark from "assets/images/localTTAdminLogo.png";
import brandLogo from "assets/images/logo.png";

export default function App() {
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    user,
    selectedMunicipal,
    tenant
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const routesSidenav = useMemo(() => {
    let sidenavRoutes = [...routes]
    if(user) {
      sidenavRoutes = [
        {
          type: "collapse",
          name: user.firstName,
          key: user.firstName,
          icon: <MDAvatar alt={user.firstName} size="sm" >
            <Icon fontSize="lg" sx={{color: "#000000"}}>admin_panel_settings</Icon>
          </MDAvatar>,
          // icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
          collapse: [
            {
              name: "Logout",
              key: "logout",
              route: "/authentication/sign-in",
              component: <SignIn />,
            },
          ]
        },
        { type: "divider", key: "divider-0" },      
        // ...sidenavRoutes
        ...sidenavRoutes.filter(r => {
          if(r?.collapse && r?.collapse.length > 0) {
            r.collapse = r.collapse.filter(r1 => {
              if(r1?.accessRoles && user?.role) {
                return r1.accessRoles.includes(user.role)
              } else {
                return true
              }
            })
            return true
          } else {
            if(r?.accessRoles && user?.role) {
              return r.accessRoles.includes(user.role)
            } else {
              return true
            }
          }
        })
      ]
    }
    return sidenavRoutes
  }, [user])

  const toggleSnackbar = () => setShowSnackbar(!showSnackbar)

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  useEffect(() => {
    
    initialCalls()

    loadSession(dispatch)

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      // console.log("onAuthStateChanges", user)
      isValidSubdomain((subdomain) => {
        if (user) {
          if(!!subdomain) {
            // check if user associate with this subdomain
            if(isSubdomainUser(subdomain.toUpperCase())) {
              createUserSession(user, subdomain)
            } else {
              setShowSnackbar(true)
              logoutUser()  
            }
          } else if(!!isViewerToken() || !!isAgentToken() || !!isAdminToken()) {
            createUserSession(user)
          } else {
            setShowSnackbar(true)
            logoutUser()
          }
        } else {
          sessionOut(dispatch)
        }
      })
    });

  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const isValidSubdomain = async (next) => {
    const subdomain = getSubdomain();
    // console.log("subdomain", subdomain)
    // validate subdomain except main admin domain i.e localtt
    if(subdomain !== "localtt" && subdomain !== "localhost") {
      const subdomainSnap = await checkSubdomainExist(subdomain)
      if(subdomainSnap.exists()) {
        setTenant(dispatch, {...subdomainSnap.data()})
        next(subdomain)
      } else {
        // unregisterd sub domain
        alert(subdomain + " has not been registered as sub domain");
        logoutUser()
        window.location.href = process.env.NODE_ENV === "development"? "http://localhost:3000/" : "https://localtt.app/"
      }
    } else {
      next()
    }
  }

  const createUserSession = async (user, subdomain=null) => {
    const userData = await getAgentUserInfo(user.uid)
    // console.log("userData", userData.data())

    sessionSuccess(dispatch, {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      ...userData.data()
    })

    if(selectedMunicipal === null) {
      setSelectedMunicipal(dispatch, userData.data().municipals[0])
    }

    if(window.location.pathname.includes('/authentication/sign-in')) {
      // if(subdomain === null)
      //   navigate("/dashboard/analytics", { replace: true})
      // else
        navigate("/dashboard/kanban", { replace: true})
    }
  }

  const initialCalls = async () => {
    let data = await getLocations()
    setMunicipals(dispatch, data)

    let rptTypesIssuesData = await getReportTypesIssues()
    setReportTypesIssues(dispatch, rptTypesIssuesData)

    let rptTagsData = await getReportTags()
    setReportTags(dispatch, rptTagsData)

    if(rptTypesIssuesData.length > 0) 
      setSelectedReportType(dispatch, 'all')
  }

  const logoutUser = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      console.log("Sign-out successful.")
      sessionOut(dispatch)
      navigate('/')
    }).catch((error) => {
      // An error happened.
      console.log("Error logout", error);
      alert(error?.message || error)
    });
  }

  const getRoutes = (allRoutes) => {
    // if(tenant && !!tenant?.has_dashboard === false) {
    //   allRoutes = allRoutes.filter(r => r.key !== "analytics")
    // }
    return allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if(route.isPrivate) {
          return <Route path="/dashboard" element={<PrivateOutlet />}>
                    <Route exact path={route.route} element={route.component} key={route.key} />
                  </Route>;
        } else
          return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  }

  useEffect(() => {
    if(tenant?.name === "DMU") {
      themeDark.palette.info.main = "#C82B2B"
      theme.palette.info.main = "#C82B2B"
    } else if(tenant?.name === "WASA") { 
      themeDark.palette.info.main = "#00a1b1"
      theme.palette.info.main = "#00a1b1"
    } else if(tenant?.name === "MOWT") { 
      themeDark.palette.info.main = "#272264"
      theme.palette.info.main = "#272264"
    } else if(tenant?.name === "SRRIC") { 
      themeDark.palette.info.main = "#cd0000"
      theme.palette.info.main = "#cd0000"
    } else if(tenant?.name === "TANDTEC") { 
      themeDark.palette.info.main = "#f1e601"
      theme.palette.info.main = "#f1e601"
      // themeDark.palette.info.main = "#fef303"
      // theme.palette.info.main = "#fef303"
    }
  }, [tenant])

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brand={tenant && tenant.logo? tenant.logo : brandLogo}
            // brandName="Local TT"
            routes={routesSidenav}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        {getRoutes(routes)}
        {/* {(tenant === null) ?
          <Route path="*" element={<Navigate to="/dashboard/analytics" />} />
          : */}
          <Route path="*" element={<Navigate to="/dashboard/kanban" />} />
        {/* } */}
      </Routes>
  
      <MDSnackbar
        color="error"
        icon="notifications"
        title="Local TT"
        content="Unauthorized access!"
        open={showSnackbar}
        close={toggleSnackbar}
      />
    </ThemeProvider>
  )
}
