import { getAuth, signOut } from "firebase/auth";

import { getUserToken } from "../utils/Utils"

const BASE_URL = "https://us-central1-local-new-ae77b.cloudfunctions.net/admin-api"

export const getAllUsers = (municipalIds) => {
    return new Promise((resolve, reject) => {
        const token = getUserToken()
        if(!!token === false) return reject({
            status: 401,
            message: "Unauthorised"
        })

        municipalIds = municipalIds.join(",")
        try {
            fetch(`${BASE_URL}/users?municipalIds=${municipalIds}`, {
            method: "GET",
            headers: {
                "authorization": `Bearer ${token}`
            }
            })
            .then(res => res.json())
            .then(response => {
                console.log("get users res", response);
                if(response?.users)
                    resolve(response.users)
                else reject({
                    message: "Not found"
                })
            })
            .catch((error) => {
                console.log("error", error);
                reject(error?.response || error)
            })
            
        } catch (error) {
            console.log("error", error);
            reject(error?.response || error)
        }        
    })
}

export const getUserById = (uid) => {
    return new Promise((resolve, reject) => {
        const token = getUserToken()
        if(!!token === false) return reject({
            status: 401,
            message: "Unauthorised"
        })

        try {
            fetch(`${BASE_URL}/users/${uid}`,{
            method: "GET",
            headers: {
                "authorization": `Bearer ${token}`
            }
            })
            .then(res => res.json())
            .then(response => {
                console.log("get user res", response);
                if(response?.user)
                    resolve(response.user)
                else reject({
                    message: "Not found"
                })
            })
            .catch(error => {
                console.error('Failed to get profile information. Please try again', error);
                reject({
                    message: 'Failed to get profile information. Please try again.'
                })
            });
            
        } catch (error) {
            console.log("error", error);
            reject(error?.response || error)
        }        
    })
}

export const createUser = (data) => {
    return new Promise((resolve, reject) => {

        console.log("createUsera api", data);

        const token = getUserToken()
        if(!!token === false) return reject({
            status: 401,
            message: "Unauthorised"
        })

        try {
            fetch(`${BASE_URL}/users`,{
            method: "POST",
            headers: {
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(response => {
                console.log("createUser res", response);
                if(response.uid) {
                    resolve(response.uid)
                } else {
                    reject({
                        message: response.message
                    })
                }
            })
            .catch(error => {
                console.error('Failed to create profile. Please try again', error);
                reject({
                    message: 'Failed to create profile. Please try again.'
                })
            });
            
        } catch (error) {
            console.log("error", error);
            reject(error?.response || error)
        }        
    })
}

export const updateUsersObj = (uid, data) => {
    return new Promise((resolve, reject) => {
        const token = getUserToken()
        if(!!token === false) return reject({
            status: 401,
            message: "Unauthorised"
        })

        try {
            fetch(`${BASE_URL}/users/${uid}`,{
            method: "PUT",
            headers: {
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(response => {
                console.log("update user res", response);
                if(response?.statusCode == 200)
                    resolve(response.message)
                else reject({
                    message: response.message
                })
            })
            .catch(error => {
                console.error('Failed to update profile information. Please try again', error);
                reject({
                    message: 'Failed to update profile information. Please try again.'
                })
            });
            
        } catch (error) {
            console.log("error", error);
            reject(error?.response || error)
        }        
    })
}

// export const updateUser = (uid, data) => {
//     return new Promise((resolve, reject) => {
//         const token = getUserToken()
//         if(!!token === false) return reject({
//             status: 401,
//             message: "Unauthorised"
//         })

//         try {
//             fetch(`${BASE_URL}/users/${uid}`,{
//             method: "PATCH",
//             headers: {
//                 "authorization": `Bearer ${token}`
//             },
//             body: JSON.stringify(data)
//             })
//             .then(res => res.json())
//             .then(response => {
//                 console.log("update user res", response);
//                 if(response?.statusCode == 200)
//                     resolve(true)
//                 else reject({
//                     message: response.message
//                 })
//             })
//             .catch(error => {
//                 console.error('Failed to update profile information. Please try again', error);
//                 reject({
//                     message: 'Failed to update profile information. Please try again.'
//                 })
//             });
            
//         } catch (error) {
//             console.log("error", error);
//             reject(error?.response || error)
//         }        
//     })
// }

export const resetUserPassword = (uid, data) => {
    return new Promise((resolve, reject) => {
        const token = getUserToken()
        if(!!token === false) return reject({
            status: 401,
            message: "Unauthorised"
        })

        try {
            fetch(`${BASE_URL}/users/${uid}/reset-password`,{
            method: "PATCH",
            headers: {
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(response => {
                console.log("update user res", response);
                if(response?.statusCode == 200)
                    resolve(response.message)
                else reject({
                    message: response.message
                })
            })
            .catch(error => {
                console.error('Failed to reset password. Please try again', error);
                reject({
                    message: 'Failed to reset password. Please try again.'
                })
            });
            
        } catch (error) {
            console.log("error", error);
            reject(error?.response || error)
        }        
    })
}

// disabled = 0 or 1. Default 0
export const disableUser = (uid, disabled=0) => {
    return new Promise((resolve, reject) => {
        console.log("disableUser api", disabled);
        const token = getUserToken()
        if(!!token === false) return reject({
            status: 401,
            message: "Unauthorised"
        })

        try {
            fetch(`${BASE_URL}/users/${uid}/${disabled}`,{
                method: "PUT",
                headers: {
                    "authorization": `Bearer ${token}`
                }
            })
            .then(res => res.json())
            .then(response => {
                console.log("disabled user res", response);
                if(response?.statusCode == 200)
                    resolve(response.message)
                else reject({
                    message: response.message
                })
            })
            .catch(error => {
                console.error('Failed to update profile information. Please try again', error);
                reject({
                    message: 'Failed to update profile information. Please try again.'
                })
            });
            
        } catch (error) {
            console.log("error", error);
            reject(error?.response || error)
        }        
    })
}

export const deleteUser = (uid) => {
    return new Promise((resolve, reject) => {
        const token = getUserToken()
        if(!!token === false) return reject({
            status: 401,
            message: "Unauthorised"
        })

        try {
            fetch(`${BASE_URL}/users/${uid}`,{
                method: "DELETE",
                headers: {
                    "authorization": `Bearer ${token}`
                }
            })
            .then(res => res.json())
            .then(response => {
                console.log("deleted user res", response);
                if(response?.statusCode == 200)
                    resolve(true)
                else reject({
                    message: response.message
                })
            })
            .catch(error => {
                console.error('Failed to delete profile information. Please try again', error);
                reject({
                    message: 'Failed to delete profile information. Please try again.'
                })
            });
            
        } catch (error) {
            console.log("error", error);
            reject(error?.response || error)
        }        
    })
}

// export const createSuperUser = () => {
    
//     const data = { 
//         displayName: "Admin",
//         password: "tearadio@Admin", 
//         email: "admin@tearadio.org", 
//         role: "admin" 
//     }

//     return new Promise((resolve, reject) => {
//         try {
//             fetch(`${BASE_URL}/users/super`,{
//             method: "POST",
//             body: JSON.stringify(data)
//             })
//             .then(res => res.json())
//             .then(response => {
//                 console.log("create admin user", response);
//                 resolve(response)
//             })
            
//         } catch (error) {
//             console.log("error", error);
//             reject(error?.response || error)
//         }        
//     })
// }

export const logoutUser = () => {
    // User is signed out
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    //   window.location.reload()
    }).catch((error) => {
      // An error happened.
      console.log("Error logout", error);
      alert(error?.message || error)
    });
}