import React from "react"
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const LTTGoogleMap = compose(
  withProps({
    googleMapURL:
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)
(props => 
  <GoogleMap
    defaultZoom={props.location.zoom}
    defaultCenter={{ ...props.location.center }}
  >
    <Marker position={{ ...props.location.center }} />
  </GoogleMap>
)

export default LTTGoogleMap