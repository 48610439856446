import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

import { Formik } from "formik";
import * as yup from 'yup';

// @mui material components
import Grid from '@mui/material/Grid';
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import MoreVertIcon from "@mui/icons-material/MoreVert";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "containers/Navbars/DashboardNavbar";
import Footer from "containers/Footer";
import DataTable from "containers/Tables/DataTable";

import ResetPasswordForm from "./components/ResetPasswordForm"
import { getAllUsers, disableUser, resetUserPassword } from "services/userServices";
import { isAdminToken } from "utils/Utils"

// React context
import { useMaterialUIController } from "context";

import defaultAvatar from "assets/images/defaultAvatar.png"

import "layouts/dashboards/users/style.css"

const dataTableData = {
  columns: [
    { 
      Header: "Name", 
      accessor: "name", 
      width: "20%", 
      Cell: props => <MDBox display={'flex'} justifyContent={"center"} alignItems="center">
        <MDAvatar src={props.value.profilePicUrl || defaultAvatar} alt="profile-image" size="md" shadow="sm" />
        <MDTypography sx={{marginLeft: '10px'}} variant="body" fontWeight="regular">{`${props.value.firstName} ${props.value.lastName}`}</MDTypography>
      </MDBox> 
    },
    { Header: "Location", accessor: "locationName", width: "25%" },
    { Header: "# Reports", accessor: "totalReports", width: "10%" },
    { Header: "Phone Number", accessor: "phoneNumber", width: "100px", Cell: props => <div style={{maxWidth: "50vw"}}>{props.value || 'N/A'}</div> },
    { Header: "Version", accessor: "appVersion", width: "25%" },
    { Header: "Verified Status", accessor: "verifiedStatus", Cell: props => <div>{props.value || "INCOMPLETE"}</div>},
    { 
      Header: "Actions", 
      accessor: "actions", 
      width: "10%", 
      sorting: false,
      Cell: ({value}) => <MDBox>
        <Tooltip key={"Actions"} title={"Actions"} placement="bottom">
          {value.menu}
        </Tooltip>
      </MDBox>
    },
  ],
  rows: []
}

const initialSBConfig = {
  color: "info",
  icon: "notifications",
  content: "",
  open: false,
}

function Users() {
  const navigate = useNavigate()
  const [controller] = useMaterialUIController();
  const { darkMode, municipals, user } = controller;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState(dataTableData)
  const [snackbarConfig, setSnackbarConfig] = useState({...initialSBConfig})

  useEffect(() => {
    // if(isAdminToken()) {
      setLoading(true)
      getAllUsers(user.municipals)
        .then(users => {
          // console.log("users", users);
          setUsers(users)
          setLoading(false)
        },
        err => {
          console.log("err", err);
          setUsers([])
          setLoading(false)
        })
    // }
  }, [municipals.length])

  useEffect(() => {
    const formatUserData = users.map(u => (
      {
        ...u,
        name: {
          firstName: u.firstName,
          lastName: u.lastName,
          profilePicUrl: u.profilePicUrl
        },
        locationName: u.location? municipals.find(m => m.id === u.location)?.long_name : "",
        totalReports: u.totalReports,
        appVersion: `${(u.appVersionIOS) ? `iOS v${u.appVersionIOS}` : ``} ${(u.appVersionIOS && u.appVersionAndroid) ? ` || ` : ``} ${(u.appVersionAndroid) ? `Android v${u.appVersionAndroid}` : ``}`,
        actions: {
          menu: <MoreMenu 
                  user={u} 
                  handleMenuActions={handleMenuActions}
                />
      }
    }))
    setTableData({
      ...tableData,
      rows: [...formatUserData]
    })
  }, [users])

  const toggleSnackbar = () => setShowSnackbar(!showSnackbar)
  const toggleSnackbarConfig = () => setSnackbarConfig({...initialSBConfig})

  const handleMenuActions = (actionType, data) => {
    switch (actionType) {
      case "handleDisable":
        console.log("handleDisable", data)
        toggleUserDisableAction(data)
        break;

      case "handleView":
        navigate(`/dashboard/users/${data.firstName}`, { state: {...data}});
        break;

      case "handleResetPassword":
        resetPasswordAction(data)
        break;

      default:
        break;
    }
  }

  const toggleUserDisableAction = (user) => {
    setLoading(true)
    const disabled = user.disabled ? 0 : 1
    disableUser(user.uid, disabled)
    .then(response => {
      setSnackbarConfig({
        ...snackbarConfig,
        color: "success",
        title: "Local TT",
        content: response,
        open: true,
        close: toggleSnackbarConfig
      })

      let tempUsers = [...users]
      const targetUserIndex = tempUsers.findIndex(u => u.uid === user.uid)
      if (targetUserIndex !== -1) {
        tempUsers = [
          ...tempUsers.slice(0, targetUserIndex),
          { ...tempUsers[targetUserIndex], disabled: Boolean(disabled) },
          ...tempUsers.slice(targetUserIndex + 1, users.length),
        ]
      }
      setUsers(tempUsers)
      setLoading(false)
    },
    err => {
      console.log("err", err.message);
      setSnackbarConfig({
        ...snackbarConfig,
        color: "error",
        title: "Local TT",
        content: err.response,
        open: true,
        close: toggleSnackbarConfig
      })
      setLoading(false)
    })
  }


  const resetPasswordAction = (response) => {
    setSnackbarConfig({
      ...snackbarConfig,
      color: response.status,
      title: "Local TT",
      content: response.message,
      open: true,
      close: toggleSnackbarConfig
    })
  }

  const formatTrProps = (state = {}) => {
    return { style: {
      opacity: state.original.disabled? 0.5 : 1
    }};
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox
          position="relative"
          my={4}
          sx={({
            palette: { light, background },
            functions: { pxToRem },
            borders: { borderRadius },
          }) => ({
            "& .react-users-column": {
              backgroundColor: darkMode ? background.card : light.main,
              width: pxToRem(450),
              margin: `0 ${pxToRem(10)}`,
              padding: pxToRem(20),
              borderRadius: borderRadius.lg,
            },
          })}
        >
          <Grid container display={"flex"} justifyContent="center" alignItems={"center"}>
            <Grid item xs={12} lg={12}>
              <MDBox pt={4} my={4}>
                {loading &&
                  <MDBox sx={{
                    overflow: 'hidden'
                  }}>
                    <LinearProgress />
                  </MDBox>
                }
                  <DataTable 
                    canSearch={true}
                    table={tableData}
                    formatRowProps={(state) => formatTrProps(state)}
                  />
                {/* {isAdminToken() ? 
                  <></>
                :
                  <MDBox px={4}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Restricted Access
                    </MDTypography>
                  </MDBox>
                } */}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

      </MDBox>

      <MDSnackbar
        color="info"
        icon="notifications"
        title="Broadcast Message"
        content="Broadcast Message Sent"
        open={showSnackbar}
        close={toggleSnackbar}
      />

      <MDSnackbar
        {...snackbarConfig}
      />

      <Footer />
    </DashboardLayout>
  )
}

export default Users


const validationSchema = yup.object({
  newpassword: yup.string().min(8).max(32).matches(/^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/, "Use 8 or more characters with a mix of letters, numbers & symbols").required("This field is required"),
})

const initialValues = {
  newpassword: "",
}
const MoreMenu = ({ user, handleMenuActions }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openResetDialog, setOpenResetDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const open = Boolean(anchorEl)

  const toggleOpenResetDialog = () => {
    setOpenResetDialog(!openResetDialog)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleView = () => {
    handleMenuActions("handleView", user)
    handleClose()
  }

  const handleDisable = () => {
    handleMenuActions("handleDisable", user)
    handleClose()
  }

  const handleResetPassword = () => {
    toggleOpenResetDialog()
    handleClose()
  }

  const toggleOpenDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog)
  }
  const handleDelete = () => {
    toggleOpenDeleteDialog()
    handleClose()
  }

  const handleDeleteAction = () => {
    handleMenuActions("handleDelete", user)
    toggleOpenDeleteDialog()
  }

  const onResetPassword = (values, { setSubmitting }) => {
    resetUserPassword(user.uid, values)
      .then(success => {
        handleMenuActions("handleResetPassword", { status: "success", message: success })
        toggleOpenResetDialog()
      }, err => {
        handleMenuActions("handleResetPassword", { status: "error", message: err.message })
      })
      .finally(() => { setSubmitting(false) })
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleView}>{"View"}</MenuItem>
        <MenuItem onClick={handleDisable}>{user.disabled ? "Enable Account" : "Disable Account"}</MenuItem>
        {!!user.providerId === false &&
          <MenuItem onClick={handleResetPassword}>Reset Password</MenuItem>
        }
        {/* <MenuItem onClick={handleDelete}>Delete</MenuItem> */}
      </Menu>

      <Dialog open={openResetDialog} onClose={toggleOpenResetDialog}>
        <DialogTitle>Reset Password</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onResetPassword}
        >
          {(props) => <ResetPasswordForm {...props} onClose={toggleOpenResetDialog} />}
        </Formik>
      </Dialog>
    </>
  )
}